import React, { useEffect } from "react";
import AppRoutes from "./routes";
import "./App.css";
import { useDispatch } from "react-redux";
import { updateHeader, updateOutsideJobLink } from "store/slices/StmateSlice";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const currentWindow = window?.location?.pathname;
  const headerData = useSelector((state) => state?.STMate?.header);

  useEffect(() => {
    if (currentWindow) {
      const parts = currentWindow?.split("/jobs/");
      if (parts.length > 1) {
        const afterJobs = parts[1];
        if (headerData?.currentJob && headerData?.currentJob !== afterJobs) {
          dispatch(updateHeader(null));
        }
        dispatch(updateOutsideJobLink(afterJobs));
      }
    }
  }, []);

  return <AppRoutes />;
}

export default App;
