import AuthForm from "components/AuthForm";
import React, { useState } from "react";
import { ForgotData } from "./data";
import { forgotPassword } from "../services/Collections";
import { message } from "antd";
import {useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    let res = await forgotPassword(values);
    if (res?.status === 200 || res?.status === 201) {
      setLoading(false);
      toast.success("Password reset link sent to your email");
      navigate("/congratulation",{state:{email:values}});
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  return (
    <div className="authFormWrapper">
      <AuthForm loading={loading} onFinish={handleSubmit} data={ForgotData} />
    </div>
  );
};

export default ForgotPassword;
