import { deleteIssue, updateCategory } from "../services/Collections";
import { DeleteIcon, EditIcon } from "../utils/Icons/SvgIcons";
import { Popover } from "antd";
import React, { useState } from "react";
import { DeleteModal } from "./DeleteModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  addClientIssue,
  addSubClientIssue,
  addSuperSubClientIssue,
  addSuperSubOptionClientIssue,
} from "store/slices/SettingSlice";

export const Button = ({
  label,
  variant = "default",
  onClick,
  className = "",
  data = {},
  handleCategories,
  row,
}) => {
  // const buttonStyle =
  //   variant === "default"
  //     ? "border-[3px] border-[#000]"
  //     : "bg-white border border-[#8D939A]";
  const [deleteModal, setDeleteModal] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const usersDetails = useSelector((state) => state?.LoginSlice?.data);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const stmateData = useSelector((state) => state?.Settings);
  const {
    clientsIssues,
    subClientIssues,
    superSubClientIssues,
    activeLayerOne,
    activeLayerTwo,
    activeLayerThree,
  } = stmateData;

  const getMatchingIssueIds = (clientsIssues, activeLayerOne) => {
    const uniqueData = activeLayerOne.filter(
      (item, index, arr) => arr.indexOf(item) === arr.lastIndexOf(item)
    );
    const matchedIds = clientsIssues
      .filter((item) => uniqueData.includes(item.name)) // Filter only items where name is in data1
      .map((item) => item.id); // Map the filtered results to ids

    return [uniqueData, matchedIds];
  };
  //   let ids = getMatchingIssueIds(clientsIssues, [...activeLayerOne]);


  const PopoverContent = () => {
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [name, setName] = useState(data?.name);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const parentCategory = useSelector(
      (state) => state?.Settings?.parentCategory
    );

    const handleIds = () => {

      if (row === "second") {
        let ids = getMatchingIssueIds(clientsIssues, [...activeLayerOne]);
        return ids[1];
      } else if (row === "third") {
        let ids = getMatchingIssueIds(subClientIssues, [...activeLayerTwo]);
        return ids[1];
      } else if (row === "fourth") {
        let ids = getMatchingIssueIds(superSubClientIssues, [
          ...activeLayerThree,
        ]);
        return ids[1];
      }
    };

    const handleUpdate = async () => {
      setLoading(true);
      let requestPayload;
      if (row !== "first") {
        let ids = handleIds();

        requestPayload = {
          name: name,
          parent_id: ids?.[0],
        };
      } else {
        requestPayload = {
          name: name,
          parent_id: parentCategory?.id,
        };
      }
      let key = usersDetails?.workspaces?.[0]?.key;
      let res = await updateCategory(key, editDetails?.id, requestPayload);
      if (res?.status === 200) {
        // toast.success("Category updated successfully");
        if (row === "first") {
          dispatch(addClientIssue({ name: name, id: editDetails?.id }));
        } else if (row === "second") {
          dispatch(addSubClientIssue({ name: name, id: editDetails?.id }));
        } else if (row === "third") {
          dispatch(addSuperSubClientIssue({ name: name, id: editDetails?.id }));
        } else if (row === "fourth") {
          dispatch(
            addSuperSubOptionClientIssue({ name: name, id: editDetails?.id })
          );
        }
        setLoading(false);
        setIsEditOpen(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    };
    return (
      <div style={{ display: "flex", gap: "15px" }}>
        {!isEditOpen ? (
          <>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditDetails(data);
                setIsEditOpen(true);
              }}
            >
              <EditIcon />
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDeleteModal(true);
                setEditDetails(data);
              }}
            >
              <DeleteIcon />
            </p>
          </>
        ) : (
          <EditWrapper>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e?.target?.value)}
            />

            <div className="btns">
              <button
                style={{
                  borderColor: "red",
                  color: "red",
                }}
                onClick={() => {
                  setIsEditOpen(false);
                  setName(data?.name);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  borderColor: name?.trim() !== data?.name && "green",
                  color: name?.trim() !== data?.name && "green",
                }}
                onClick={
                  !loading && name?.trim() !== data?.name && handleUpdate
                }
              >
                {loading ? "Loading" : "Update"}
              </button>
            </div>
          </EditWrapper>
        )}
      </div>
    );
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await deleteIssue(key, editDetails?.id);
    if (res?.status === 200) {
      setDeleteLoading(false);
      toast.success("Issue deleted successfully");
      if (row === "first") {
        handleCategories();
      } else if (row === "second") {
        let ids = getMatchingIssueIds(clientsIssues, [...activeLayerOne]);
        handleCategories(1, ids?.[1]);
      } else if (row === "third") {
        let ids = getMatchingIssueIds(subClientIssues, [...activeLayerTwo]);
        handleCategories(2, ids?.[1]);
      } else if (row === "fourth") {
        let ids = getMatchingIssueIds(superSubClientIssues, [
          ...activeLayerThree,
        ]);
        handleCategories(3, ids?.[1]);
      }
    } else {
      setDeleteLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {deleteModal && (
        <DeleteModal
          handleClose={() => setDeleteModal(false)}
          open={deleteModal}
          title="Are you sure you want to delete it ?"
          description="All your process and data will be saved."
          button="Delete"
          isLoading={deleteLoading}
          handleSubmit={handleDelete}
        />
      )}
      <Popover content={PopoverContent}>
        <button
          className={`py-2 h-[42px] ${className}`}
          onClick={onClick}
        >
          {label}
        </button>
      </Popover>
    </>
  );
};

const EditWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;

  .btns {
    display: flex;
    gap: 15px;
  }

  input {
    width: 100%;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #8d939a;
  }
  button {
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #8d939a;
    background-color: #fff;
    color: #8d939a;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    // cursor: pointer;
  }
`;
