import React from "react";
import SubHeader from "./SubHeader";

const Stmate = () => {
  return (
    <div className="h-[100%] font-inter bg-[#f3f4f6] relative">
      <SubHeader />
    </div>
  );
};

export default Stmate;
