import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Button, Input, Space } from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons';

const initialData = {
    section1: {
        id: 'section1',
        title: 'Select the relevant HVAC Sales issue(s)',
        items: ['IAQ', 'Temp Issues', 'Furnace / Heater', 'Ductwork', 'AC / HP', 'Mini-Split']
    },
    section2: {
        id: 'section2',
        title: 'What is going on with the ductwork?',
        items: ['Air Flow', 'Asbestos', 'Rodents', 'Add-ons', 'Insulation', 'Code Violation']
    },
    section3: {
        id: 'section3',
        title: "What's wrong with the ductwork insulation?",
        items: ['Thickness', 'Compression', 'Moisture/Water Damage', 'Air Leaks']
    }
};

// export const DragAndDropDemo = ({ selectedService }) => {
export default function DragAndDropDemo({ selectedService }) {
    const [section1, setSection1] = useState(initialData.section1);
    const [section2, setSection2] = useState(initialData.section2);
    const [section3, setSection3] = useState(initialData.section3);

    const [expandedSections, setExpandedSections] = useState({
        section1: true,
        section2: true,
        section3: true
    });

    const [newItemText, setNewItemText] = useState({
        section1: '',
        section2: '',
        section3: ''
    });

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const sourceSection = getSection(source.droppableId);
        const destSection = getSection(destination.droppableId);

        if (source.droppableId === destination.droppableId) {
            const newItems = Array.from(sourceSection.items);
            const [reorderedItem] = newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, reorderedItem);

            updateSection(source.droppableId, { ...sourceSection, items: newItems });
        } else {
            const sourceItems = Array.from(sourceSection.items);
            const destItems = Array.from(destSection.items);
            const [removedItem] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removedItem);

            updateSection(source.droppableId, { ...sourceSection, items: sourceItems });
            updateSection(destination.droppableId, { ...destSection, items: destItems });
        }
    };

    const getSection = (sectionId) => {
        switch (sectionId) {
            case 'section1':
                return section1;
            case 'section2':
                return section2;
            case 'section3':
                return section3;
            default:
                return null;
        }
    };

    const updateSection = (sectionId, newData) => {
        switch (sectionId) {
            case 'section1':
                setSection1(newData);
                break;
            case 'section2':
                setSection2(newData);
                break;
            case 'section3':
                setSection3(newData);
                break;
        }
    };

    const toggleSection = (sectionId) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));
    };

    const addNewItem = (sectionId) => {
        if (newItemText[sectionId].trim() !== '') {
            const section = getSection(sectionId);
            const updatedSection = {
                ...section,
                items: [...section.items, newItemText[sectionId].trim()]
            };
            updateSection(sectionId, updatedSection);
            setNewItemText(prev => ({ ...prev, [sectionId]: '' }));
        }
    };

    const renderSection = (section) => (
        <Card
            key={section.id}
            style={{ marginBottom: 16 }}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 level={4}>{section.title}</h2>
                    <Button
                        type="text"
                        icon={expandedSections[section.id] ? <UpOutlined /> : <DownOutlined />}
                        onClick={() => toggleSection(section.id)}
                    />
                </div>
            }
        >
            {expandedSections[section.id] && (
                <Droppable droppableId={section.id}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ minHeight: 50 }}
                        >
                            {section.items.map((item, index) => (
                                <Draggable key={item} draggableId={`${section.id}-${item}`} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                backgroundColor: '#f0f0f0',
                                                ...provided.draggableProps.style
                                            }}
                                        >
                                            {item}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )}
            {expandedSections[section.id] && (
                <Space.Compact style={{ width: '100%', marginTop: 16 }}>
                    <Input
                        value={newItemText[section.id]}
                        onChange={(e) => setNewItemText(prev => ({ ...prev, [section.id]: e.target.value }))}
                        placeholder="New item"
                    />
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => addNewItem(section.id)}>
                        Add
                    </Button>
                </Space.Compact>
            )}
        </Card>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div style={{ maxWidth: 800, margin: '0 auto', padding: 16 }}>
                {renderSection(section1)}
                {renderSection(section2)}
                {renderSection(section3)}
            </div>
        </DragDropContext>
    );
};






