import { useEffect, useRef, useState } from "react";
import ReactSortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import { FaRegEdit } from "react-icons/fa";
import {
  addCategory,
  deleteIssue,
  getAllCategories,
  replaceCategories,
  updateCategory,
} from "services/Collections";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { Input, Modal, Tooltip } from "antd";
import { toast } from "react-toastify";

function App() {
  const usersDetails = useSelector((state) => state?.LoginSlice?.data);
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteIssueId, setDeleteIssue] = useState(false);
  const [newNodeTitle, setNewNodeTitle] = useState("");
  const [path, setPath] = useState(null);
  const [editNoda, setEditNode] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const handleCancel = () => {
    setAddModalVisible(false);
    setDeleteIssue(false);
    setNewNodeTitle("");
    setEditNode(null);
    setPath(null);
  };

  const handleDragAndDrop = async ({ node, nextParentNode, prevPath }) => {
    try {
      const arrOFChildIds = nextParentNode?.children?.map((ele) => ele?.id);
      const previousParentNode = getNodeAtPath({
        treeData,
        path: prevPath.slice(0, -1),
        getNodeKey: ({ treeIndex }) => treeIndex,
      })?.node;

      const parentId = previousParentNode?.id;
      const key = usersDetails?.workspaces?.[0]?.key;
      const payload = { parent_id: nextParentNode?.id, order: arrOFChildIds };

      // if (parentId !== nextParentNode?.id) {
      await replaceCategories(key, node?.id, payload);
      // }
    } catch (error) {
      console.error("error in drag&drop", error);
    }
  };

  const handleEdit = async () => {
    if (newNodeTitle == "" || newNodeTitle?.length == 0) {
      return toast.error("Category should not be empty");
    }
    setModalLoading(true);
    let key = usersDetails?.workspaces?.[0]?.key;

    if (path.length > 1) {
      const parentPath = path.slice(0, -1);

      const parentNode = getNodeAtPath({
        treeData,
        path: parentPath,
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).node;

      const parentId = parentNode?.id;

      let payload = {
        name: newNodeTitle,
        parent_id: parentId,
      };

      let res = await updateCategory(key, editNoda?.id, payload);
  
      if (res?.status == 200) {
        const newData = treeData.map((item) => {
          const updateNode = (currentNode) => {
            if (currentNode === editNoda) {
              return { ...currentNode, ["title"]: newNodeTitle };
            }
            if (currentNode.children) {
              return {
                ...currentNode,
                children: currentNode.children.map(updateNode),
              };
            }
            return currentNode;
          };

          return updateNode(item);
        });
        setTreeData(newData);
        setModalLoading(false);
        setNewNodeTitle("");
        setEditNode(null);
        setPath(null);
        setLoading(false);
        toast.success("Category Updated Successfully.", { theme: "colored" });
      } else {
      
        setEditNode(null);
        setPath(null);
        setLoading(false);
        toast.error("Something went wrong!", { theme: "colored" });
      }
    }
  };

  const handleAdd = async () => {
    setModalLoading(true);
    if (newNodeTitle == "" || newNodeTitle?.length == 0) {
      return toast.error("Category Should not be empty");
    }

    const parentNode = getNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    }).node;

    const parentId = parentNode?.id;

    let res = await handleAddCategories(newNodeTitle, parentId);

    if (res?.id) {
      const newNode = { title: res?.name, id: res?.id };
      const newTree = addNodeUnderParent({
        treeData,
        parentKey: path[path.length - 1],
        expandParent: true,
        newNode,
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData;
      setTreeData(newTree);
      setModalLoading(false);
    }

    setAddModalVisible(false);
    setNewNodeTitle("");
    setPath(null);
    setModalLoading(false);
    toast.success("Category Added Successfully.", {
      style: {
        color: "black",
      },
    });
  };

  const handleRemove = async () => {
    setModalLoading(true);
    const parentNode = getNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    }).node;

    const parentId = parentNode?.id;

    const res = await handleDelete(parentId);

    if (res) {
      const newTree = removeNodeAtPath({
        treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      setTreeData(newTree);
      setModalLoading(false);
    }
    setDeleteIssue(false);
    setPath(null);
    setModalLoading(false);
    toast.success("Category Successfully Deleted.", {
      style: {
        color: "black",
      },
    });
  };

  function convertCategories(arr) {
    return arr.map((item) => ({
      title: item?.name,
      sectionPrompt: "",
      helpText: "",
      id: item?.id,
      children:
        item.subcategories.length > 0
          ? convertCategories(item.subcategories)
          : [],
    }));
  }

  const handleAddCategories = async (inputIssue, parent) => {
    let requestPayload = {
      parent_id: parent,
      name: inputIssue,
    };
    if (!parent) {
      delete requestPayload.parent_id;
    }
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await addCategory(key, requestPayload);
    if (res?.status == 200) {
      return res?.data;
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      return null;
    }
  };

  const handleDelete = async (id) => {
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await deleteIssue(key, id);
    if (res?.status == 200) {
      return res?.data;
    } else {
      return null;
    }
  };

  const handleGetAllCategories = async () => {
    setLoading(true);
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await getAllCategories(key);
    if (res?.status === 200) {
      const result = convertCategories(res?.data);
      setTreeData(result);
      setLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (addModalVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [addModalVisible]);

  useEffect(() => {
    handleGetAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader size={34} color={"#000"} />
      </div>
    );
  }

  return (
    <div>
      <div style={{ height: "97dvh" }}>
        <ReactSortableTree
          treeData={treeData}
          onChange={setTreeData}
          onMoveNode={handleDragAndDrop}
          canDrag={({ path }) => {
            return path.length !== 1;
          }}
          canDrop={({ nextParent, nextPath }) => {
            if (nextPath?.length == 1) {
              return false;
            } else if (nextPath.length > 5) {
              return false;
            } else {
              return nextParent ? nextParent.id !== "root_node_id" : true;
            }
          }}
          generateNodeProps={({ node, path }) => ({
            title: (
              <div className="w-[360px] flex px-2 py-[6px] h-10 gap-1">
                {node.title?.length > 40 ? (
                  <>
                    <Tooltip placement="top" title={node.title}>
                      <input
                        className="w-full h-auto font-normal text-sm rounded-[8px] px-2"
                        type="text"
                        value={node.title}
                        placeholder="Button Label"
                        disabled={!node.isEditing}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <input
                      className="w-full h-auto font-normal text-sm rounded-[8px] px-2"
                      type="text"
                      value={node.title}
                      placeholder="Button Label"
                      disabled={!node.isEditing}
                    />
                  </>
                )}
                <div className="flex gap-2 items-center">
                  {path?.length < 5 && (
                    <IoMdAddCircleOutline
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setPath(path);
                        setAddModalVisible(true);
                      }}
                    />
                  )}
                  <FaRegEdit
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditNode(node);
                      setPath(path);
                      setNewNodeTitle(node?.title);
                    }}
                  />
                  <MdDeleteOutline
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDeleteIssue(true);
                      setPath(path);
                    }}
                  />
                </div>
              </div>
            ),
          })}
        />
      </div>
      {addModalVisible && (
        <Modal
          title="Add Category"
          visible={addModalVisible}
          onCancel={handleCancel}
          onOk={handleAdd}
          confirmLoading={modalLoading}
        >
          <Input
            ref={inputRef}
            placeholder="Enter category"
            value={newNodeTitle}
            onChange={(e) => setNewNodeTitle(e.target.value)}
            minLength={1}
            maxLength={100}
          />
        </Modal>
      )}
      {editNoda && (
        <Modal
          title="Edit Category"
          onCancel={handleCancel}
          visible={editNoda ? true : false}
          onOk={handleEdit}
          confirmLoading={modalLoading}
        >
          <Input
            placeholder="Enter Category"
            value={newNodeTitle}
            onChange={(e) => setNewNodeTitle(e.target.value)}
          />
        </Modal>
      )}
      {deleteIssueId && (
        <Modal
          title="Are you sure you want to delete this? Deleting this category will also permanently delete all its subcategories."
          visible={deleteIssueId}
          onCancel={handleCancel}
          onOk={handleRemove}
          confirmLoading={modalLoading}
        />
      )}
    </div>
  );
}

export default App;
