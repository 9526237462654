import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Otp from "components/Otp";
import { ButtonComp } from "components/ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";
import { sendOtp, verifyEmail } from "../services/Collections";
import { toast } from "react-toastify";

const inputStyle = {
  width: "100%",
  height: "52px",
  margin: "0 0px",
  borderRadius: "10px",
  borderStyle: "none",
  background: "rgb(61 151 242 / 20%)",
  display: "flex",
  alignItems: "center",
  padding: 0,
};

export const Congratulations = () => {
  const emailvalue = useLocation().state?.email;
  const [otp, setOtp] = useState();
  const [errroshow, setErrroshow] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(20);

  const handleSubmit = async () => {
    
    let requestPayload = {
      code: otp,
      email: emailvalue,
    };
    if (otp === undefined || otp === "") {
      setErrroshow(true);
    } else {
      setErrroshow(false);
      setLoading(true);
      let res = await verifyEmail(requestPayload);
      if (res?.status === 200 || res?.status === 201) {
        setLoading(false);
        toast.success("Email verified successfully");
        navigate("/step-1");
      } else {
        setLoading(false);
      }
    }
  };
  // const resendOtp = () => {
  //   navigate("/forgot-password");
  // };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const resendOTP = async () => {
    let requestPayload = {
      email: emailvalue?.email,
    };
    let res = await sendOtp(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      toast.success("OTP sent to your email");
      setTimeLeft(20);
    } else {
      // toast.error(
      //   res?.response?.data?.message || res?.message || "Something went wrong"
      // );
    }
  };

  return (
    <div className="w-full h-full flex items-center">
      <div style={{ textAlign: "center" }}>
        <header className="text-4xl font-bold">
          Check your email for a code
        </header>
        <p className="text-base text-[#b8b8b8] my-3">
          We've sent a 6-character code to{" "}
          <span style={{ color: "#3d3d3d" }}>
            {emailvalue && emailvalue?.email} <br />
          </span>
          The code expires shortly, Please enter it soon.
        </p>
        <div className="inner-Section">
          <VerifyOtp>
            <Otp otp={otp} setOtp={setOtp} inputStyle={inputStyle} />
            {errroshow && <p className="text-[red]">OTP is required</p>}
          </VerifyOtp>
          {timeLeft <= 0 ? (
            <p onClick={resendOTP}>
              <u className="text-[#1E88E5] font-bold absolute right-10 mb-2.5 cursor-pointer">
                Resend OTP
              </u>
            </p>
          ) : (
            <p className="text-[#3d3d3d] font-bold absolute right-10 mb-2.5">{`00:${
              timeLeft < 10 ? `0${timeLeft}` : `${timeLeft}`
            }`}</p>
          )}
          <SendOtpWrapper>{/* <span>Resend</span> 00:00 */}</SendOtpWrapper>
          <div className="w-full flex gap-3 mt-10">
            <ButtonComp
              textcard={"Back"}
              handleAction={() => navigate(-1)}
              color={"#fff"}
              background={"#F5F5F5"}
            />
            <ButtonComp
              textcard={"Continue"}
              handleAction={handleSubmit}
              // handleAction={() => navigate("/step-1")}
              color={"#FFFFFF"}
              background={"#3D97F2"}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const VerifyOtp = styled.div`
  width: 100%;
  margin: 16px 0 !important;
`;

const SendOtpWrapper = styled.div`
  text-align: end;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-size: 14px;
  color: rgb(61, 61, 61);
  span {
    font-size: 16px;
    color: ${({ color }) => color || "rgb(160 155 155)"};
    cursor: pointer;
  }
`;
