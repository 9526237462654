import React, { useState } from "react";
import AuthForm from "../components/AuthForm";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangePasswordData } from "./data";
import { ChangePasswordApi } from "../services/Collections";
import { toast } from "react-toastify";

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const location = useLocation().state;
  const navigate = useNavigate();
  const [checkStrongPass, setCheckStrongPass] = useState(false);

  const handleSubmit = async (values) => {
    if (!checkStrongPass) {
      return;
    } else {
      setLoading(true);
      let requestPayload = {
        id: location?.id,
        old_password: values?.old_password,
        new_password: values?.password,
      };
      let res = await ChangePasswordApi(requestPayload);
      if (res?.status === 200 || res?.status === 201) {
        toast.success("Password changed successfully");
        navigate("/");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    }
  };

  return (
    <div className="authFormWrapper my-[10px]">
      <AuthForm
        loading={loading}
        onFinish={handleSubmit}
        data={ChangePasswordData}
        setCheckStrongPass={setCheckStrongPass}
      />
    </div>
  );
}
