import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const Loader = ({ size, color }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size,
        color: color,
      }}
      spin
    />
  );
  return <Spin indicator={antIcon} />;
};

