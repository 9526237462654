import Header from "pages/stmate/Header";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SubHeader from "./SubHeader";

export const Settings = () => {
  const stmateId = useSelector((state) => state?.STMate?.outsideJob);
  const [jobDetails, setJobDetails] = useState(null);

  return (
    <div className="h-[100%] font-inter bg-[#f3f4f6]">
      {/* <Header jobDetails={jobDetails} setJobDetails={setJobDetails} /> */}
      <SubHeader />
    </div>
  );
};
