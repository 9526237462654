import React, { useEffect, useState } from "react";
import {
  getCategories,
  addCategory,
  replaceCategories,
} from "services/Collections";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addClientIssue,
  addSubClientIssue,
  addSuperSubClientIssue,
  addSuperSubOptionClientIssue,
  resetAllActivebtns,
  updateLayerfour,
  updateLayerOne,
  updateLayerThree,
  updateLayerTwo,
} from "store/slices/SettingSlice";
import { Loader } from "components/Loader";
import { StMateButton } from "pages/stmate/SubHeader";
import { AddButton } from "../../components/SettingsAddIssueButton";
import { Button } from "../../components/SettingsIssueButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIcon } from "utils/Icons/SvgIcons";

const Chevron = ({ direction, className }) => (
  <span className={`material-icons ${className}`}>
    {direction === "down" ? <FaChevronDown /> : <FaChevronUp />}
  </span>
);

const IssueButton = ({
  label,
  // isActive,
  onClick,
  data,
  handleCategories,
  row,
}) => (
  <Button
    label={label}
    // variant={isActive ? "default" : "outline"}
    onClick={onClick}
    data={data}
    handleCategories={handleCategories}
    row={row}
    className="m-1"
  />
);

const getClassNames = (array, name) => {
  const baseClasses = "flex items-center justify-center h-[52px] px-4 text-sm font-medium rounded-lg transition duration-300"
  const activeClasses = "bg-black text-white shadow-lg border border-gray-800"
  const inactiveClasses = "bg-white text-black border border-gray-300 hover:bg-gray-100"

  const conditionalClasses = array?.includes(name) ? activeClasses : inactiveClasses

  return `${baseClasses} ${conditionalClasses}`
}

export const IssuesComp = ({ selectedService }) => {
  const dispatch = useDispatch();
  const workspace = useSelector(
    (state) => state?.LoginSlice?.data?.workspaces?.[0]
  );
  const stmateData = useSelector((state) => state?.Settings);
  const {
    clientsIssues,
    subClientIssues,
    superSubClientIssues,
    superSubOptionsClientIssues,
    activeLayerOne,
    activeLayerTwo,
    activeLayerThree,
    activeLayerFour,
    parentCategory,
  } = stmateData;


  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isSubExpanded, setIsSubExpanded] = useState(true);
  const [isSuperSubExpanded, setIsSuperSubExpanded] = useState(true);
  const [issueText, setIssueText] = useState("");
  const [showInputText, setShowInputText] = useState(false);
  const [addIssue, setAddIssue] = useState(false);
  const [addSubIssue, setAddSubIssue] = useState(false);
  const [addOptionsIssue, setAddOptionsIssue] = useState(false);
  const [inputIssue, setInputIssue] = useState("");
  const [loading, setLoading] = useState(false);
  const [subloading, setSubLoading] = useState(false);
  const [optionsloading, setOptionsLoading] = useState(false);
  const [subOptionsloading, setSubOptionsLoading] = useState(false);
  const [addSuperOptionsIssue, setAddSuperOptiosIssue] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  // const [addOptionsIssue, setAddOptiosIssue] = useState(false);

  const getMatchingIssueIds = (issues, activeLayer) => {
    const uniqueData = activeLayer.filter(
      (item, index, arr) => arr.indexOf(item) === arr.lastIndexOf(item)
    );
    const matchedIds = issues
      .filter((item) => uniqueData.includes(item.name))
      .map((item) => item.id);

    return [uniqueData, matchedIds];
  };

  const handleIssueClick = (issue) => {
    // let ids = getMatchingIssueIds(clientsIssues, [
    //   ...activeLayerOne,
    //   issue?.name,
    // ]);
    dispatch(updateLayerOne(issue?.name));
    setIsMainExpanded(true);
    setIsSubExpanded(false);
    setIsSuperSubExpanded(false);
    setIssueText("");
    setShowInputText(false);
    let ids = [issue?.id];
    handleSubCategories(1, ids);
  };

  const handleSubIssueClick = (subIssue) => {
    let ids = getMatchingIssueIds(subClientIssues, [
      ...activeLayerTwo,
      subIssue?.name,
    ]);
    dispatch(updateLayerTwo(subIssue?.name));
    setIsSubExpanded(true);
    setIsSuperSubExpanded(false);
    setIssueText("");
    setShowInputText(false);
    // let ids =
    handleSubCategories(2, ids[1]);
  };

  const handleOptionClick = (option) => {
    let ids = getMatchingIssueIds(superSubClientIssues, [
      ...activeLayerThree,
      option?.name,
    ]);
    dispatch(updateLayerThree(option?.name));
    setIsSuperSubExpanded(true);
    setShowInputText(true);
    setIssueText("");
    handleSubCategories(3, ids[1]);
  };

  const handleSuperOptionClick = (option) => {
    let ids = getMatchingIssueIds(superSubOptionsClientIssues, [
      ...activeLayerFour,
      option?.name,
    ]);
    dispatch(updateLayerfour(option?.name));
    setShowInputText(true);
    setIssueText("");
  };

  const handleAddCategories = async (inputIssue, type, parent) => {
    setAddLoading(true);
    let requestPayload = {
      parent_id: parent?.[0],
      name: inputIssue,
    };

    if (!parent) {
      delete requestPayload.parent_id;
    }
    let res = await addCategory(workspace?.key, requestPayload);
    if (res?.status == 200) {
      setAddLoading(false);
      return res?.data;
    } else {
      setAddLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      return null;
    }
  };

  const handleAddIssue = async () => {
    let result = await handleAddCategories(inputIssue, null, [
      parentCategory?.id,
    ]);
    dispatch(addClientIssue(result));
    setAddIssue(!addIssue);
  };

  const AddOnGoing = async () => {
    let ids = getMatchingIssueIds(clientsIssues, [...activeLayerOne]);

    let result = await handleAddCategories(inputIssue, 1, ids[1]);
    dispatch(addSubClientIssue(result));
    setAddSubIssue(!addSubIssue);
  };

  const AddOptionIssue = async () => {
    let ids = getMatchingIssueIds(subClientIssues, [...activeLayerTwo]);
    let result = await handleAddCategories(inputIssue, 2, ids[1]);
    dispatch(addSuperSubClientIssue(result));
    setAddOptionsIssue(!addOptionsIssue);
  };

  const AddSuperOptionIssue = async () => {
    let ids = getMatchingIssueIds(superSubClientIssues, [...activeLayerThree]);
    let result = await handleAddCategories(inputIssue, 3, ids[1]);
    dispatch(addSuperSubOptionClientIssue(result));
    setAddSuperOptiosIssue(!addSuperOptionsIssue);
  };

  // const handleAddIssue = () => {
  //   dispatch(
  //     addClientIssue([
  //       ...clientsIssues,
  //       { name: inputIssue, id: Date.now().toString() },
  //     ])
  //   );
  //   setAddIssue(false);
  //   setInputIssue("");
  // };

  // const AddOnGoing = () => {
  //   dispatch(
  //     addSubClientIssue([
  //       ...subClientIssues,
  //       { name: inputIssue, id: Date.now().toString() },
  //     ])
  //   );
  //   setAddSubIssue(false);
  //   setInputIssue("");
  // };

  // const AddOptionIssue = () => {
  //   dispatch(
  //     addSuperSubClientIssue([
  //       ...superSubClientIssues,
  //       { name: inputIssue, id: Date.now().toString() },
  //     ])
  //   );
  //   setAddOptionsIssue(false);
  //   setInputIssue("");
  // };

  // const AddSuperOptionIssue = () => {
  //   dispatch(
  //     addSuperSubOptionClientIssue([
  //       ...superSubOptionsClientIssues,
  //       { name: inputIssue, id: Date.now().toString() },
  //     ])
  //   );
  //   setAddOptionsIssue(false);
  //   setInputIssue("");
  // };

  const handleReset = () => {
    dispatch(resetAllActivebtns(true));
  };

  const handleSubCategories = async (type, id) => {
    if (type === 1) setSubLoading(true);
    else if (type === 2) setOptionsLoading(true);
    else setSubOptionsLoading(true);

    if (id?.length > 0) {
      let params = new URLSearchParams();
      id?.forEach((el) => el && params.append("parent", el));
      const res = await getCategories(workspace?.key, params?.toString());
      if (res?.status === 200) {
        if (type === 1) dispatch(addSubClientIssue(res?.data));
        else if (type === 2) dispatch(addSuperSubClientIssue(res?.data));
        else dispatch(addSuperSubOptionClientIssue(res?.data));
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    } else {
      if (type === 1) {
        dispatch(addSubClientIssue([]));
        dispatch(addSuperSubClientIssue([]));
        dispatch(addSuperSubOptionClientIssue([]));
      } else if (type === 2) {
        dispatch(addSuperSubClientIssue([]));
        dispatch(addSuperSubOptionClientIssue([]));
      } else {
        dispatch(addSuperSubOptionClientIssue([]));
      }
    }

    if (type === 1) setSubLoading(false);
    else if (type === 2) setOptionsLoading(false);
    else setSubOptionsLoading(false);
  };

  const handleCategories = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    let id = parentCategory?.id;
    params.append("parent", id);

    const res = await getCategories(workspace?.key, params?.toString());
    if (res?.status === 200) {
      dispatch(addClientIssue(res?.data));
      setLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const handlePayloadForReplace = (section) => {
    if (section === "section1") {
      return { parent_id: parentCategory?.id };
    } else if (section === "section2") {
      let ids = getMatchingIssueIds(clientsIssues, [...activeLayerOne]);
      return { parent_id: ids?.[1]?.[0] };
    } else if (section === "section3") {
      let ids = getMatchingIssueIds(subClientIssues, [...activeLayerTwo]);
      return { parent_id: ids?.[1]?.[0] };
    } else if (section === "section4") {
      let ids = getMatchingIssueIds(superSubClientIssues, [
        ...activeLayerThree,
      ]);
      return { parent_id: ids?.[1]?.[0] };
    }
  };

  const handleRemoveCategories = (array, id) => {
    let result = (array ?? [])?.filter((el) => el?.id != id);
    return result;
  };

  const handleReplacecategories = async (
    categoryId,
    section,
    draggableSection
  ) => {
    let key = workspace?.key;
    let requestPayload = {
      ...handlePayloadForReplace(section),
    };
    let res = await replaceCategories(key, categoryId, requestPayload);
    if (res?.status === 200) {
      // return true;
      // toast.success("Category replaced successfully");
    } else {
      // return false;
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (destination?.droppableId !== source?.droppableId) {
      handleReplacecategories(
        result?.draggableId,
        destination?.droppableId,
        source?.droppableId
      );
      if (source?.droppableId === "section1") {
        dispatch(
          addClientIssue(
            handleRemoveCategories(clientsIssues, result?.draggableId)
          )
        );
      } else if (source?.droppableId === "section2") {
        dispatch(
          addSubClientIssue(
            handleRemoveCategories(subClientIssues, result?.draggableId)
          )
        );
      } else if (source?.droppableId === "section3") {
        dispatch(
          addSuperSubClientIssue(
            handleRemoveCategories(superSubClientIssues, result?.draggableId)
          )
        );
      } else if (source?.droppableId === "section4") {
        dispatch(
          addSuperSubOptionClientIssue(
            handleRemoveCategories(
              superSubOptionsClientIssues,
              result?.draggableId
            )
          )
        );
      }
    }

    let sourceItems, destItems, updateAction;

    switch (source.droppableId) {
      case "section1":
        sourceItems = [...clientsIssues];
        updateAction = addClientIssue;
        break;
      case "section2":
        sourceItems = [...subClientIssues];
        updateAction = addSubClientIssue;
        break;
      case "section3":
        sourceItems = [...superSubClientIssues];
        // Determine which section the item was dragged from
        updateAction = addSuperSubClientIssue;
        break;
      case "section4":
        sourceItems = [...superSubOptionsClientIssues];
        updateAction = addSuperSubOptionClientIssue;
        break;
      default:
        return;
    }

    if (source.droppableId === destination.droppableId) {
      const [reorderedItem] = sourceItems.splice(source.index, 1);
      sourceItems.splice(destination.index, 0, reorderedItem);
      dispatch(updateAction(sourceItems));
    } else {
      switch (destination.droppableId) {
        case "section1":
          destItems = [...clientsIssues];
          updateAction = addClientIssue;
          break;
        case "section2":
          // If the item was moved within the same section
          destItems = [...subClientIssues];
          // Reorder the items in the section
          updateAction = addSubClientIssue;
          break;
        case "section3":
          destItems = [...superSubClientIssues];
          // Determine which section the item was dropped in
          updateAction = addSuperSubClientIssue;
          break;
        case "section4":
          destItems = [...superSubOptionsClientIssues];
          updateAction = addSuperSubOptionClientIssue;
          break;
        default:
          return;
      }
      const [removedItem] = sourceItems?.splice(source.index, 1);
      destItems.splice(destination.index, 0, removedItem);
      dispatch(updateAction(destItems));
    }
  };

  useEffect(() => {
    parentCategory && handleCategories();
  }, [parentCategory]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="bg-gray-200 px-4 pb-4 rounded-md">
        <div className="flex justify-end items-center gap-2">
          {activeLayerOne?.length > 0 && (
            <StMateButton onClick={handleReset}>Reset</StMateButton>
          )}
        </div>
      </div>

      <div className="bg-gray-100 p-6 rounded-lg">
        {loading ? (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <Loader color="#426286" />
          </div>
        ) : (
          <>
            {/* <h2 className="text-lg mb-2 font-semibold">
              Select the relevant{" "}
              <b className="capitalize">{selectedService}</b> issue(s)
            </h2> */}

            <Droppable droppableId="section1">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-wrap gap-2 mb-2"
                >
                  {clientsIssues?.map((issue, index) => (
                    <Draggable
                      key={issue.id}
                      draggableId={issue.id.toString()}
                      index={index}
                      isDragDisabled={activeLayerOne.includes(issue.name)}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={getClassNames(activeLayerOne, issue?.name)}
                        >
                          <DragIcon isActive={activeLayerOne?.includes(issue?.name)} />
                          <IssueButton
                            label={issue?.name}
                            // isActive={activeLayerOne?.includes(issue?.name)}
                            onClick={() => handleIssueClick(issue)}
                            data={issue}
                            handleCategories={handleCategories}
                            row="first"
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <div className="mb-8">
              {addIssue ? (
                <div className="h-25 rounded-lg py-4 px-4 bg-gray-300 mt-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold">Add Issue</span>
                    <b
                      className="font-black cursor-pointer"
                      onClick={() => {
                        setAddIssue(!addIssue);
                        setInputIssue("");
                      }}
                    >
                      X
                    </b>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="text"
                      placeholder="Enter issue"
                      className="w-full overflow-y-auto h-[45px] px-3 rounded-[8px] outline-none"
                      onChange={(e) => setInputIssue(e.target.value)}
                    />
                    <AddButton
                      handleAddClick={handleAddIssue}
                      loading={addLoading}
                    />
                  </div>
                </div>
              ) : (
                <AddButton handleAddClick={() => setAddIssue(!addIssue)} />
              )}
            </div>

            {loading ? (
              ""
            ) : subloading ? (
              <>
                <div className="w-full h-[50px] flex justify-center items-center">
                  <Loader color="#426286" />
                </div>
              </>
            ) : (
              <>
                {activeLayerOne?.length > 0 && (
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Open close  */}
                    <div className="flex justify-between items-center">
                      <button
                        className="flex items-center text-sm font-normal"
                        onClick={() => setIsMainExpanded(!isMainExpanded)}
                      >
                        <Chevron
                          direction={isMainExpanded ? "up" : "down"}
                          className="mr-2"
                        />
                        {activeLayerOne}
                      </button>
                    </div>

                    {isMainExpanded && (
                      <>
                        <hr className="h-1 my-4 bg-black" />
                        <div>
                          <p className="mb-2 text-lg font-medium">
                            What is going on with the {activeLayerOne}?
                          </p>
                          <Droppable droppableId="section2">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="flex flex-wrap gap-2 mb-3"
                              >
                                {subClientIssues?.map((subIssue, index) => (
                                  <Draggable
                                    key={subIssue.id}
                                    draggableId={subIssue.id.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={getClassNames(activeLayerTwo, subIssue?.name)}
                                      >
                                        <DragIcon isActive={activeLayerTwo?.includes(subIssue?.name)} />
                                        <IssueButton
                                          label={subIssue?.name}
                                          isActive={activeLayerTwo?.includes(
                                            subIssue?.name
                                          )}
                                          onClick={() =>
                                            handleSubIssueClick(subIssue)
                                          }
                                          data={subIssue}
                                          handleCategories={handleSubCategories}
                                          row="second"
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>

                          <div className="mb-8">
                            {addSubIssue ? (
                              <div className="h-25 rounded-lg py-4 px-4 bg-gray-300 mt-4">
                                <div className="flex justify-between items-center mb-2">
                                  <span className="font-semibold">
                                    Add Issue
                                  </span>
                                  <b
                                    className="font-black cursor-pointer"
                                    onClick={() => {
                                      setAddSubIssue(!addSubIssue);
                                      setInputIssue("");
                                    }}
                                  >
                                    X
                                  </b>
                                </div>
                                <div className="flex gap-3 items-center">
                                  <input
                                    type="text"
                                    placeholder="Enter issue"
                                    className="w-full overflow-y-auto h-[45px] px-3 rounded-[8px] outline-none"
                                    onChange={(e) =>
                                      setInputIssue(e.target.value)
                                    }
                                  />
                                  <AddButton
                                    handleAddClick={AddOnGoing}
                                    loading={addLoading}
                                  />
                                </div>
                              </div>
                            ) : (
                              <AddButton
                                handleAddClick={() =>
                                  setAddSubIssue(!addSubIssue)
                                }
                              />
                            )}
                          </div>

                          {optionsloading ? (
                            <>
                              <div className="w-full h-[50px] flex justify-center items-center">
                                <Loader color="#426286" />
                              </div>
                            </>
                          ) : (
                            <>
                              {activeLayerTwo?.length > 0 && (
                                <div className="border border-black py-4 px-4 rounded-lg m-4">
                                  <div className="flex justify-between items-center">
                                    <button
                                      className="flex items-center text-sm font-normal"
                                      onClick={() =>
                                        setIsSubExpanded(!isSubExpanded)
                                      }
                                    >
                                      <Chevron
                                        direction={
                                          isSubExpanded ? "up" : "down"
                                        }
                                        className="mr-2"
                                      />
                                      {activeLayerTwo}
                                    </button>
                                  </div>

                                  {isSubExpanded && (
                                    <>
                                      <hr className="h-1 my-4 bg-black" />
                                      <div>
                                        <p className="mb-2 text-lg font-medium">
                                          What's wrong with the {activeLayerOne}{" "}
                                          {activeLayerTwo}?
                                        </p>
                                        <Droppable droppableId="section3">
                                          {(provided) => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                              className="flex flex-wrap gap-2 mb-3"
                                            >
                                              {superSubClientIssues?.map(
                                                (option, index) => (
                                                  <Draggable
                                                    key={option.id}
                                                    draggableId={option.id.toString()}
                                                    index={index}
                                                  >
                                                    {(provided) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={getClassNames(activeLayerThree, option?.name)}
                                                      >
                                                        <DragIcon isActive={activeLayerThree?.includes(option?.name)} />
                                                        <IssueButton
                                                          label={option?.name}
                                                          isActive={activeLayerThree?.includes(
                                                            option?.name
                                                          )}
                                                          onClick={() =>
                                                            handleOptionClick(
                                                              option
                                                            )
                                                          }
                                                          data={option}
                                                          handleCategories={
                                                            handleSubCategories
                                                          }
                                                          row="third"
                                                        />
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              )}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>

                                        <div className="mb-8">
                                          {addOptionsIssue ? (
                                            <div className="h-25 rounded-lg py-4 px-4 bg-gray-300 mt-4">
                                              <div className="flex justify-between items-center mb-2">
                                                <span className="font-semibold">
                                                  Add Issue
                                                </span>
                                                <b
                                                  className="font-black cursor-pointer"
                                                  onClick={() => {
                                                    setAddOptionsIssue(
                                                      !addOptionsIssue
                                                    );
                                                    setInputIssue("");
                                                  }}
                                                >
                                                  X
                                                </b>
                                              </div>
                                              <div className="flex gap-3 items-center">
                                                <input
                                                  type="text"
                                                  placeholder="Enter issue"
                                                  className="w-full overflow-y-auto h-[45px] px-3 rounded-[8px] outline-none"
                                                  onChange={(e) =>
                                                    setInputIssue(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <AddButton
                                                  handleAddClick={
                                                    AddOptionIssue
                                                  }
                                                  loading={addLoading}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <AddButton
                                              handleAddClick={() =>
                                                setAddOptionsIssue(
                                                  !addOptionsIssue
                                                )
                                              }
                                            />
                                          )}
                                        </div>

                                        {/* ... (keep the existing structure) */}

                                        {!subOptionsloading &&
                                          activeLayerThree?.length > 0 && (
                                            <div className="border border-black py-4 px-4 rounded-lg m-4">
                                              <div className="flex justify-between items-center">
                                                <button
                                                  className="flex items-center text-sm font-normal"
                                                  onClick={() =>
                                                    setIsSubExpanded(
                                                      !isSubExpanded
                                                    )
                                                  }
                                                >
                                                  <Chevron
                                                    direction={
                                                      isSubExpanded
                                                        ? "up"
                                                        : "down"
                                                    }
                                                    className="mr-2"
                                                  />
                                                  {activeLayerThree}
                                                </button>
                                              </div>

                                              {isSuperSubExpanded && (
                                                <>
                                                  <hr className="h-1 my-4 bg-black" />
                                                  <div>
                                                    <p className="mb-2 text-lg font-medium">
                                                      What's wrong with the{" "}
                                                      {activeLayerThree}?
                                                    </p>
                                                    <Droppable droppableId="section4">
                                                      {(provided) => (
                                                        <div
                                                          {...provided.droppableProps}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          className="flex flex-wrap gap-2 mb-3"
                                                        >
                                                          {superSubOptionsClientIssues?.map(
                                                            (option, index) => (
                                                              <Draggable
                                                                key={option.id}
                                                                draggableId={option.id.toString()}
                                                                index={index}
                                                              >
                                                                {(provided) => (
                                                                  <div
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={getClassNames(activeLayerFour, option?.name)}
                                                                  >
                                                                    <DragIcon isActive={activeLayerFour?.includes(option?.name)} />
                                                                    <IssueButton
                                                                      label={
                                                                        option?.name
                                                                      }
                                                                      isActive={activeLayerFour?.includes(
                                                                        option?.name
                                                                      )}
                                                                      onClick={() =>
                                                                        handleSuperOptionClick(
                                                                          option
                                                                        )
                                                                      }
                                                                      data={
                                                                        option
                                                                      }
                                                                      handleCategories={
                                                                        handleSubCategories
                                                                      }
                                                                      row="fourth"
                                                                    />
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                            )
                                                          )}
                                                          {provided.placeholder}
                                                        </div>
                                                      )}
                                                    </Droppable>

                                                    <div className="mb-8">
                                                      {addSuperOptionsIssue ? (
                                                        <div className="h-25 rounded-lg py-4 px-4 bg-gray-300 mt-4">
                                                          <div className="flex justify-between items-center mb-2">
                                                            <span className="font-semibold">
                                                              Add Issue
                                                            </span>
                                                            <b
                                                              className="font-black cursor-pointer"
                                                              onClick={() => {
                                                                setAddSuperOptiosIssue(
                                                                  !addSuperOptionsIssue
                                                                );
                                                                setInputIssue(
                                                                  ""
                                                                );
                                                              }}
                                                            >
                                                              X
                                                            </b>
                                                          </div>
                                                          <div className="flex gap-3 items-center">
                                                            <input
                                                              type="text"
                                                              placeholder="Enter issue"
                                                              className="w-full overflow-y-auto h-[45px] px-3 rounded-[8px] outline-none"
                                                              onChange={(e) =>
                                                                setInputIssue(
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                            <AddButton
                                                              handleAddClick={
                                                                AddSuperOptionIssue
                                                              }
                                                              loading={
                                                                addLoading
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <AddButton
                                                          handleAddClick={() =>
                                                            setAddSuperOptiosIssue(
                                                              !addSuperOptionsIssue
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </div>

                                                    {/* ... (keep the rest of the structure) */}
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </DragDropContext>
  );
};
