export const loginData = {
  header: "Sign In",
  subHeader: "Sign in to start the communications with your mate",
  buttonText: "Continue",
  footer: "Not signed up yet? ",
  subFooter: "Create an account",
  email: true,
  password: true,
  confirmPassword: false,
  forgotPassword: true,
  footerRoute: "/signup",
};

export const CreateAccountData = {
  header: "Sign Up",
  subHeader: "Sign up to start the communications with your mate",
  buttonText: "Continue",
  footer: "Already have an account?",
  subFooter: "Sign In",
  email: true,
  password: true,
  confirmPassword: true,
  forgotPassword: false,
  footerRoute: "/",
};

export const ForgotData = {
  header: "Forgot Password ?",
  subHeader: "No worries, we’ll send you reset instructions",
  buttonText: "Send link to the email",
  footer: "",
  subFooter: "Back",
  email: true,
  password: false,
  confirmPassword: false,
  forgotPassword: false,
  footerRoute: "/",
};

export const SetNewPasswordData = {
  header: "Set New Password",
  subHeader: "New password must be different than previous password.",
  buttonText: "Reset Password",
  footer: "",
  subFooter: "",
  email: false,
  password: true,
  confirmPassword: true,
  forgotPassword: false,
  footerRoute: "/",
};

export const ChangePasswordData = {
  header: "Change Password",
  subHeader: "New password must be different than previous password.",
  buttonText: "Submit",
  footer: "",
  subFooter: "",
  email: false,
  oldPassword: true,
  password: true,
  confirmPassword: true,
  forgotPassword: false,
  footerRoute: "/",
};
