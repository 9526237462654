import React from "react";
import { Plus } from "lucide-react"

export const AddButton = ({ handleAddClick, loading }) => {
  return (
    <button
      onClick={!loading && handleAddClick}
      className="gap-2 bg-white px-6 py-2 rounded-full flex items-center justify-center text-sm font-normal text-black hover:bg-black hover:text-white transition-colors duration-300 ease-in-out border border-gray-100 shadow-md hover:shadow-lg"
    >
      {!loading && <Plus size={18} />}
      {loading ? "Loading..." : "Add"}
    </button>
  );
};
