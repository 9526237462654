const { Popover } = require("antd");
const { useState } = require("react");
const { HiDotsVertical } = require("react-icons/hi");
const { MdDragIndicator } = require("react-icons/md");

const ServiceItem = ({
    service,
    description,
    onRemove,
    onhandleCheck,
    parentIndex,
    childIndex,
    setddedit,
    setEditData,
    deleteModal,
    draggableProps,
    dragHandleProps,
  }) => {
    const content = (
      <div className="grid gap-2">
        <p
          className="cursor-pointer text-sm font-semibold border border-black rounded-[20px] py-[5px] text-center"
          onClick={() => {
            setddedit({ parentIndex, childIndex });
            setEditData(() => ({ name: service, summary: description }));
            handleClick();
          }}
        >
          Edit
        </p>
        <p
          onClick={() => deleteModal(parentIndex, childIndex)}
          className="cursor-pointer text-sm font-semibold border border-black rounded-[20px] py-[5px] text-center"
        >
          Delete
        </p>
      </div>
    );
  
    const [visible, setVisible] = useState(false);
  
    const handleClick = () => {
      setVisible(false); // Close the popover
    };
  
    const handleVisibleChange = (newVisible) => {
      setVisible(newVisible);
    };
  
    return (
      <>
        <div
          className={`p-6 flex gap-4 m-4 rounded-xl ${
            onRemove && "bg-[#e9e9e9]"
          }`}
          {...draggableProps}
        >
          <div className="flex items-center justify-between ">
            <div className="w-[32px] flex items-center justify-between flex-col h-full gap-3">
              <input
                name={service}
                type="checkbox"
                className="accent-black"
                value={onRemove}
                checked={onRemove}
                onClick={(e) => {
                  onhandleCheck(e.target.checked, parentIndex, childIndex);
                }}
              />
              <span className="cursor-move" {...dragHandleProps}>
                <MdDragIndicator />
              </span>
            </div>
          </div>
          <div className="w-full">
            <span>{service}</span>
            <div className="flex items-center gap-6 whitespace-pre-line text-base">
              <div className="first-letter-uppercase lowercase text-base text-gray-600">
                {description}
              </div>
            </div>
          </div>
          <Popover
            style={{ width: "20px" }}
            content={content}
            trigger={"click"}
            arrow={false}
            visible={visible}
            onVisibleChange={handleVisibleChange}
          >
            <HiDotsVertical style={{ width: "40px", cursor: "pointer" }} />
          </Popover>
        </div>
      </>
    );
  };

  export default ServiceItem;