import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedType } from "store/slices/StmateSlice";
import { styled } from "styled-components";
import { IssuesComp } from "./IssuesComp";
import { getCategories } from "../../services/Collections";
import {
  addClientIssue,
  parentCategory,
  resetAllActivebtns,
} from "store/slices/SettingSlice";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";
// import IssuesComp from "./IssuesComp";

const SubHeader = () => {
  const dispatch = useDispatch();
  const stmateData = useSelector((state) => state?.STMate?.type);
  const [selectedService, setSelectedService] = useState(stmateData);
  const workspace = useSelector(
    (state) => state?.LoginSlice?.data?.workspaces?.[0]
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleCheckboxChange = (event, data) => {
    setSelectedService(event.target.name);
    dispatch(selectedType(event.target.name));
    dispatch(parentCategory(data));
    dispatch(resetAllActivebtns(true));
  };

  const handleCategories = async () => {
    setLoading(true);
    const res = await getCategories(workspace?.key);
    if (res?.status === 200) {
      setData(res?.data);
      // dispatch(addClientIssue(res?.data));
      setLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCategories();
  }, []);

  return (
    <>
      <div className="bg-gray-200 p-4 rounded-md">
        <div className="flex items-center justify-between">
          {!loading ? (
            <div className="flex space-x-4">
              {data?.length > 0 &&
                data?.map((ele, idx) => {
                  if (ele?.name) {
                    return (
                      <div className="flex items-center space-x-2" key={idx}>
                        <input
                          name={ele?.name}
                          type="checkbox"
                          className="accent-black"
                          checked={selectedService === ele?.name}
                          onChange={(e) => handleCheckboxChange(e, ele)}
                        />
                        <label htmlFor={ele} className="sub-header-labels">
                          {ele?.name}
                        </label>
                      </div>
                    );
                  }
                })}
              {/* <div className="flex items-center space-x-2">
              <input
                name="hvac-service"
                type="checkbox"
                className="accent-black"
                checked={selectedService === "hvac-service"} // Ensure only one can be checked
                onChange={handleCheckboxChange}
              />
              <label htmlFor="hvac-service" className="sub-header-labels">
                HVAC Service
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                name="hvac-sales"
                type="checkbox"
                className="accent-black"
                checked={selectedService === "hvac-sales"}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="hvac-sales" className="sub-header-labels">
                HVAC Sales
              </label>
            </div> */}
              {/* <div className="flex items-center space-x-2">
              <input
                name="plumbing"
                type="checkbox"
                className="accent-black"
                checked={selectedService === "plumbing"}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="plumbing" className="sub-header-labels">
                Plumbing
              </label>
            </div> */}
            </div>
          ) : (
            <div className="w-full h-[40px] flex justify-center items-center">
              <Loader color="#426286" size={24} />
            </div>
          )}
        </div>
        {/* <div className="flex justify-end items-center">
          <div className="flex items-center gap-3">
            <StMateButton>+ image</StMateButton>
            <StMateButton>+ diagnostic</StMateButton>
            <StMateButton>+ age</StMateButton>
          </div>
          <StMateButton>submit</StMateButton>
        </div> */}
      </div>
      {selectedService && <IssuesComp selectedService={selectedService} />}
    </>
  );
};

export default SubHeader;

export const StMateButton = styled.button`
  height: 21px;
  padding: 3px 7px;
  border-radius: 15px;
  border: 1px solid black;
  font-size: 12px;
  line-height: 14.52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
