import { Modal, Select, Switch } from "antd";
import React from "react";
import { styled } from "styled-components";

const data = [
  {
    value: "default",
    label: "Default",
  },
  {
    value: "informal",
    label: "Informal",
  },
  {
    value: "robust and wordy",
    label: "Robust and wordy",
  },
];

const SubmitModal = ({ open, setOpen, onOk, loading }) => {
  const handleCancel = () => {
    setOpen(!open);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={onOk}
      confirmLoading={loading}
      okText="Submit"
    >
      <SubmitModalStyle>
        <div className="heading">Options</div>
        <div className="wrap">
          <Switch onChange={onChange} />
          <span>Generate Estimates</span>
        </div>
        <div className="wrap">
          <Switch onChange={onChange} />
          <span>Use Emojis</span>
        </div>

        <div className="heading headingTwo">Style</div>
        <Select
          defaultValue="default"
          style={{
            width: 250,
          }}
          allowClear
          options={data}
          placeholder="select it"
        />
      </SubmitModalStyle>
    </Modal>
  );
};

export default SubmitModal;

const SubmitModalStyle = styled.div`
  .wrap {
    margin-bottom: 15px;
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .headingTwo {
    font-size: 16px;
  }
`;
