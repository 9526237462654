import { createSlice } from "@reduxjs/toolkit";
import { uniqueArrayOfObjects } from "utils/Functions";

const initialState = {
  outsideJob: "",
  header: null,
  type: [],
  parentCategory: null,
  formData: [],
  clientsIssues: [],
  requestPayload: null,
  requestResponse: null,
  newdata: {},
  expendedItems: [],
};

export const StmateSlice = createSlice({
  name: "StmateSlice",
  initialState,
  reducers: {
    initalFromUpdate: (state, actions) => {
      state.newdata = actions.payload;
    },

    updateHeader: (state, actions) => {
      state.header = actions.payload;
    },

    updateExpendedItems: (state, actions) => {
      state.expendedItems = actions.payload;
    },

    savingRequestPayload: (state, actions) => {
      if (actions.payload) {
        state.requestPayload = actions?.payload;
      }
    },

    savingRequestResponse: (state, actions) => {
      if (actions.payload) {
        state.requestResponse = actions?.payload;
      }
    },

    updateOutsideJobLink: (state, actions) => {
      if (actions.payload) {
        state.outsideJob = actions?.payload;
      }
    },

    selectedType: (state, actions) => {
      if (actions.payload) {
        state.type = actions.payload;
      }
    },

    parentCategory: (state, actions) => {
      if (actions.payload) {
        state.parentCategory = actions.payload;
      }
    },

    addClientIssue: (state, actions) => {
      if (actions.payload) {
        let data = [];
        if (Array.isArray(actions.payload)) {
          const preData = [...state.clientsIssues, ...actions?.payload];
          data = preData.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
        } else {
          data = uniqueArrayOfObjects(
            [...state.clientsIssues, actions.payload],
            "id"
          );
        }
        state.clientsIssues = data;
      }
    },

    updateClientIssue: (state, actions) => {
      if (actions.payload) {
        state.clientsIssues = uniqueArrayOfObjects(
          [...(state.clientsIssues ?? []), ...(actions.payload ?? [])],
          "id"
        );
      }
    },

    manageForm: (state, actions) => {
      if (actions.payload) {
        state.formData = actions.payload;
      }
    },

    resetStmateSlice: (state, action) => initialState,

    resetAllActivebtns: (state) => {
      return {
        ...initialState,
        parentCategory: state.parentCategory,
        outsideJob: state.outsideJob,
        header: state.header,
      };
    },
  },
});

export const {
  initalFromUpdate,
  updateOutsideJobLink,
  selectedType,
  addClientIssue,
  updateClientIssue,
  resetStmateSlice,
  manageForm,
  resetAllActivebtns,
  parentCategory,
  savingRequestPayload,
  savingRequestResponse,
  updateExpendedItems,
  updateHeader,
} = StmateSlice.actions;
export default StmateSlice.reducer;
