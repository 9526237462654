import axios from "axios";

// Environment and API setup
const BaseURL = process.env.REACT_APP_APIBASE;
const Version = "/api/v1/";
const EndPoint = `${BaseURL}${Version}`;

const Api = axios.create({
  baseURL: EndPoint,
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

// Function to get the token
const getAuthHeader = () => {
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;

  const btoaToken = btoa(`${username}:${password}`);
  return `Basic ${btoaToken}`;
};

const clearStoreData = () => {
  // Clear user-related store data
};

// Axios Request Interceptor
Api.interceptors.request.use(
  (config) => {
    const authHeader = getAuthHeader();

    config.headers = {
      Authorization: authHeader,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

// Axios Response Interceptor
Api.interceptors.response.use(
  (response) => {
    if (response.data?.status === 401) {
      clearStoreData();
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      clearStoreData();
    }

    return Promise.reject(error);
  }
);

export default Api;
