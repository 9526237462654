/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import CustomCard from "../../components/CustomCard";
// import { searchJobListing } from "../../services/Collections";
// import { ModalWrapper } from "../regions/RegionsIndex";
import { Switch, Table, message } from "antd";
import {
  ModalWrapper,
  NameWrapper,
  SearchWrapper,
  TableWrapper,
} from "../business/Business";
import { useSelector } from "react-redux";
import { WechatOutlined } from "@ant-design/icons";
import { Loader } from "../../../components/Loader";
import {
  getJobTypeListing,
  updateJobType,
} from "../../../services/Collections";
import { PaginationBox } from "../../../components/Pagination";
import { toast } from "react-toastify";
import { render } from "@testing-library/react";

export const JobType = () => {
  //   const [editModal, setEditModal] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const usersDetails = useSelector((state) => state?.LoginSlice?.data);
  const [search, setSearch] = useState("");
  const [openInfo, setOpenInfo] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesize, setPageSize] = useState(30);
  const [searchWord, setSearchWord] = useState("");
  const [searchData, setSearchData] = useState([]);
  //   const [pagesize, setPageSize] = useState(50);
  //   const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [updateLoading, setUpdateLoading] = useState(null);

  const handleUpdateJobType = async (id, value) => {
    let requestPayload = {
      stmate_active: value === true ? 1 : 0,
    };
    setUpdateLoading(id);
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await updateJobType(key, id, requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      toast.success("Job type updated successfully");
      setJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.id === id
            ? { ...job, stmate_active: requestPayload.stmate_active }
            : job
        )
      );

      if (searchWord) {
        setSearchData((prevSearchData) =>
          prevSearchData.map((job) =>
            job.id === id
              ? { ...job, stmate_active: requestPayload.stmate_active }
              : job
          )
        );
      }

      setUpdateLoading(null);
    } else {
      setUpdateLoading(null);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  let columns = [
    {
      title: "Sr. No.",
      dataIndex: "index",
      render: (val) => (
        <NameWrapper>
          <p>{val}.</p>
        </NameWrapper>
      ),
    },
    {
      title: "NAME",
      dataIndex: "name",
      render: (val) => (
        <NameWrapper>
          <p>{val ? val : "NA"}</p>
        </NameWrapper>
      ),
    },
    // {
    //   title: "WEBHOOK",
    //   dataIndex: "webhook",
    //   render: (val) => (
    //     <NameWrapper>
    //       <p>{val ? val : "NA"}</p>
    //     </NameWrapper>
    //   )
    // },
    {
      title: "STMate Active",
      dataIndex: "stmate_active",
      render: (val, data) => (
        <NameWrapper>
          <div>
            {/* <u>INFO</u> */}
            <Switch
              value={val == 0 ? false : true}
              checked={val == 0 ? false : true}
              loading={updateLoading === data?.id}
              onChange={(val) => handleUpdateJobType(data?.id, val)}
            />
          </div>
        </NameWrapper>
      ),
    },
  ];

  // const getTableData = (searchData, listing) => {
  //   if (searchData?.length > 0) {
  //     return searchData;
  //   } else {
  //     return listing;
  //   }
  // };

  const handleJobsListing = async () => {
    setLoading(true);
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await getJobTypeListing(key);
    if (res?.status == 200 || res?.message == "OK") {
      //   setTotalCount(res?.total);
      let arr = [];
      Array.isArray(res?.data) &&
        res?.data?.length > 0 &&
        res?.data?.map((ele, idx) => {
          let obj = {
            index: idx + 1,
            ...ele,
          };

          arr.push(obj);
        });
      setLoading(false);
      setTotalCount(res?.workspaceusersCount);

      setJobs(arr);
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  // const optimizedFn = useCallback(debounce(handleJobsSearch), []);

  const handleSearch = (word) => {
    setSearchWord(word);
    if (word) {
      let updated = jobs?.filter((el) =>
        el?.name?.toLowerCase()?.includes(word.toLowerCase())
      );
      setSearchData(updated);
    } else {
      setSearchData([]);
    }
  };

  const getTableData = (searchData, listing) => {
    if (searchData?.length > 0 || searchWord) {
      return searchData;
    } else {
      return listing;
    }
  };

  const onChange = (page) => {
    setPageNumber(page);
    setPageSize(30);
  };

  useEffect(() => {
    // optimizedFn();
  }, []);

  useEffect(() => {
    handleJobsListing(pageNumber);
  }, []);

  return (
    <div>
      {/* {editModal && (
        <EditModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          data={editData}
          type="job"
          handleUpdateRegions={handleUpdateWebhook}
          loading={modalLoading}
        />
      )} */}
      {/* {openInfo && (
        <JobInfoModal
          open={openInfo}
          handleModal={() => setOpenInfo(false)}
          data={infoData}
          userID={connectedWorkspace?._id}
        />
      )} */}
      <TitleWrapper>
        <h4></h4>
        <SearchWrapper>
          <label>Search:</label>{" "}
          <input
            placeholder="Search..."
            type="text"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </SearchWrapper>
      </TitleWrapper>

      {loading ? (
        <ModalWrapper>
          <Loader size={28} color={"#3D97F2"} />
        </ModalWrapper>
      ) : (
        <>
          {/* {searchData.length > 0 || searchWord ? (
            <>
              {searchData?.length > 0 ? (
                <ContentWrapper>
                  {searchData?.map((ele, index) => (
                    <CustomCard
                      key={index}
                      cardData={ele}
                      open={() => setEditModal(true)}
                      setEditData={setEditData}
                    />
                  ))}
                </ContentWrapper>
              ) : (
                <NoData>
                  <h1>NO DATA FOUND</h1>
                </NoData>
              )}
            </>
          ) : (
            <ContentWrapper>
              {jobs?.map((ele, index) => (
                <CustomCard
                  key={index}
                  cardData={ele}
                  open={() => setEditModal(true)}
                  setEditData={setEditData}
                />
              ))}
            </ContentWrapper>
          )} */}
          <TableWrapper>
            <Table
              className="gap-table"
              columns={columns}
              dataSource={getTableData(searchData, jobs)}
              pagination={false}
            />
          </TableWrapper>
        </>
      )}

      {/* <PaginationBox
        limit={pagesize}
        current={pageNumber}
        total={totalCount}
        onChange={onChange}
        showSizeChanger={false}
      /> */}
    </div>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px;
    line-height: 20px !important;
    color: #101010 !important;
    margin: 10px 0 !important;
  }
`;

export const ContentWrapper = styled.div`
display:grid;
grid-template-columns: repeat(3,1fr);
padding: 16px !important;
gap:16px;
padding: 0 0 86px 0;
margin-bottom:70px;

@media(max-width: 800px){
  grid-template-columns: repeat(2,1fr);
}
@media(max-width: 500px){
  grid-template-columns: repeat(1,1fr);
}
  .cardWrapper {
    cursor: pointer;
    background: #fff;.
    box-shadow: 0px 2px 12px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding:15px;
    .audioPlayDiv {
      display: flex;
      align-items: center;
      gap: 8px;
      h1 {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
      button {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        background: #f3edff;
        border-radius: 40px;
        border: none;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;

        color: #8045fe;
      }
      .playArrowDiv {
        width: 40px;
        height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
        border-radius: 50%;
        background: #edf2f8ed;
      }
    }
    .cardInfo{
      display:flex;
      justify-content:space-between;
      margin-top:20px;
      
        ul{
          list-style-type:none;
          .forGray{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
          }
          .forGray1{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
            margin-bottom:20px;

            img{
              width: 20px;
              height: 20px;
              border-radius: 50%;

            }
          }
            li{
                font-family: Lato, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #101010;
                display:flex;
                align-items:center;
                gap:6px;
                line-height: 20px;
            }
        }
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #101010;
  }
`;
