import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initalFromUpdate,
  manageForm,
  parentCategory,
  resetAllActivebtns,
  savingRequestPayload,
  savingRequestResponse,
  selectedType,
} from "store/slices/StmateSlice";
import { styled } from "styled-components";
import IssuesComp from "./IssuesComp";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";
import {
  categoriesIssues,
  getCategories,
  JobtypeBeforeSubmit,
} from "services/Collections";
import {
  cleanObject,
  convertToServiceString,
  deepClone,
  deepEqual,
  findDeepestLevel,
  removeIsActive,
  toggleActiveStatus,
  toggleExpendStatus,
} from "utils/Functions";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import SubmitModal from "./SubmitModal";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const SubHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    type: stmateData,
    parentCategory: pCat,
    newdata: formData,
  } = useSelector(({ STMate }) => STMate);
  const stmateDataa = useSelector((state) => state?.STMate);
  const { type, newdata, outsideJob, requestResponse, requestPayload } =
    stmateDataa;
  const workspace = useSelector(
    (state) => state?.LoginSlice?.data?.workspaces?.[0]
  );
  const headerData = useSelector((state) => state?.STMate?.header);

  const [selectedService, setSelectedService] = useState(stmateData);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [header, setHeader] = useState(true);

  const handleReset = () => {
    setSelectedService([]);
    dispatch(resetAllActivebtns());
  };

  const handleCheckboxChange = (event) => {
    if (formData[event?.target?.name]) {
      const newData = toggleActiveStatus(formData, event?.target?.name);
      dispatch(initalFromUpdate(newData));
    } else {
      const clone = deepClone(formData);
      const result = {
        ...clone,
        [event?.target?.name]: { subItems: {}, isActive: true, isExpend: true },
      };
      dispatch(initalFromUpdate(result));
    }

    if (selectedService?.includes(event?.target?.name)) {
      const data = selectedService?.filter(
        (ele) => ele !== event?.target?.name
      );
      setSelectedService(data);
      dispatch(selectedType(data));
    } else {
      setSelectedService((pre) => [...pre, event.target.name]);
      dispatch(selectedType([...selectedService, event.target.name]));
    }

    dispatch(parentCategory(data));
  };

  const handleCategories = async () => {
    setLoading(true);
    const res = await getCategories(workspace?.key);
    if (res?.status === 200) {
      setData(res?.data);
      setLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const handleDeepestLevel = () => {
    let result = false;
    let modifiedNewData = removeIsActive(newdata);

    if (Object.keys(modifiedNewData)?.length <= 0) {
      result = true;
      return result;
    }

    if (type?.length > 0) {
      for (let index = 0; index < type?.length; index++) {
        if (
          modifiedNewData[type?.[index]] &&
          findDeepestLevel(modifiedNewData[type?.[index]] ?? {}) < 2
        ) {
          result = true;
          break;
        }
      }
    }
    return result;
  };

  const getSystemPrompt = async () => {
    setSubmitLoading(true);

    let res = await JobtypeBeforeSubmit(
      workspace?.key,
      headerData?.data?.job_number
    );

    console.log("System prompt: ", res?.data?.prompt);
    handleSubmit();
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    let payload = removeIsActive(newdata);
    let modifiedPayload = cleanObject(payload);

    // if (Object.keys(modifiedNewData[type]?.subItems ?? {})?.length === 0) {
    //   toast.error("Please select categories");
    //   setSubmitLoading(false);
    //   return;
    // } else if (findDeepestLevel(modifiedNewData[type] ?? {}) < 4) {
    //   setSubmitLoading(false);
    //   toast.error("Please select sub categories");
    //   return;
    // }

    let reqPayload = {
      job: outsideJob,
      categories: modifiedPayload,
    };
    if (requestResponse) {
      if (deepEqual(requestPayload?.categories ?? {}, modifiedPayload)) {
        navigate("/v1/stmate-form");
        setSubmitLoading(false);
        console.log("User prompt: ", reqPayload);
        console.log("User prompt response: ", requestResponse);
        return;
      }
    }

    console.log("User prompt: ", reqPayload);
    const res = await categoriesIssues(workspace?.key, reqPayload);

    if (res?.status === 200) {
      if (!res?.data?.ai_response) {
        toast.warning(
          "Oops! information does not exist, Please try with different input"
        );
        setSubmitLoading(false);
        return;
      }
      console.log("User prompt response: ", res?.data);
      dispatch(savingRequestPayload(reqPayload));
      dispatch(savingRequestResponse(res?.data?.ai_response));
      dispatch(
        manageForm([
          {
            Summary: res?.data?.ai_response,
            oldSummary: res?.data?.ai_response,
            RecommendedServices: [],
            buttonStatus: "Push to Service Titan",
            title:"",
            oldTitle:"",
          },
        ])
      );
      navigate("/v1/stmate-form");
      toast.success(res?.response?.data?.message || res?.message);
      setSubmitLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setSubmitLoading(false);
    }
  };

  const handleExpendFun = (val) => {
    const newData = toggleExpendStatus(newdata, val);
    dispatch(initalFromUpdate(newData));
  };

  const filterService = useMemo(() => {
    if (selectedService?.length > 0) {
      let data = Array.from(pCat ?? [])?.filter((e) =>
        selectedService?.includes(e?.name)
      );
      const result = [];
      selectedService?.forEach((ele) => {
        if (data?.find((e) => e.name == ele)) {
          result?.push(data?.find((e) => e.name == ele));
        }
      });
      return result;
    }
  }, [selectedService]);

  useEffect(() => {
    handleCategories();
  }, []);

  return (
    <>
      {openModal && (
        <SubmitModal
          open={openModal}
          setOpen={setOpenModal}
          onOk={handleSubmit}
        />
      )}
      <TestDiv height={header ? "280px" : "40px"}>
        <Header
          jobDetails={headerData?.data}
          header={header}
          setHeader={setHeader}
        />
        {headerData?.data && (
          <div className="bg-gray-200 p-4">
            <div className="flex items-center justify-between">
              {!loading ? (
                <div className="w-full flex gap-3 justify-between">
                  <div className="flex gap-3 justify-between">
                    {data?.length > 0 &&
                      data?.map((ele, idx) => {
                        if (ele?.name) {
                          return (
                            <div
                              className="flex items-center space-x-2"
                              key={idx}
                            >
                              <input
                                id={convertToServiceString(ele?.name)}
                                name={ele?.name}
                                type="checkbox"
                                className="accent-black"
                                value={selectedService?.includes(ele?.name)}
                                checked={selectedService?.includes(ele?.name)}
                                onChange={(e) => handleCheckboxChange(e)}
                              />
                              <label
                                htmlFor={convertToServiceString(ele?.name)}
                                className="sub-header-labels"
                              >
                                {ele?.name}
                              </label>
                            </div>
                          );
                        }
                      })}
                  </div>

                  <div className="w-fit flex justify-end items-center gap-2">
                    {
                      // Object.keys(newdata[type] ?? {})?.length > 0
                      type?.length > 0 && (
                        <StMateButton
                          className="text-lg font-semibold"
                          style={{
                            width: "70px",
                            height: "35px",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          onClick={handleReset}
                        >
                          Reset
                        </StMateButton>
                      )
                    }
                    <StMateButton
                      onClick={() =>
                        !handleDeepestLevel() &&
                        !submitLoading &&
                        getSystemPrompt()
                      }
                      className="text-lg font-semibold"
                      style={{
                        width: "70px",
                        height: "35px",
                        fontSize: "13px",
                        cursor: handleDeepestLevel()
                          ? "not-allowed"
                          : "pointer",
                      }}
                    >
                      {submitLoading ? (
                        <Loader color="#426286" size="20px" />
                      ) : (
                        "Submit"
                      )}
                    </StMateButton>
                  </div>
                </div>
              ) : (
                <div className="w-full h-[40px] flex justify-center items-center">
                  <Loader color="#426286" size={24} />
                </div>
              )}
            </div>
          </div>
        )}
      </TestDiv>
      {headerData?.data &&
        !loading &&
        selectedService?.length > 0 &&
        filterService &&
        filterService?.map((ele, index) => (
          <div
            key={ele?.id}
            className={`mb-2 ${index == 0 ? "pt-[83px]" : "pt-[0px]"}`}
          >
            <ExpendCloseBtn
              key={ele?.id}
              text={selectedService[index]}
              onClick={() => handleExpendFun(selectedService[index])}
              changeIcon={newdata[selectedService[index]]?.isExpend}
            />
            {newdata[selectedService[index]]?.isExpend && (
              <IssuesComp
                key={ele?.id}
                type={selectedService[index]}
                parentId={ele?.id}
              />
            )}
          </div>
        ))}
    </>
  );
};

export default SubHeader;

export const StMateButton = styled.button`
  height: 21px;
  padding: 3px 7px;
  border-radius: 15px;
  border: 1px solid black;
  font-size: 12px;
  line-height: 14.52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TestDiv = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  position: sticky;
  top: 0;
`;

const ExpendCloseBtn = ({ onClick, changeIcon, text }) => {
  return (
    <div
      onClick={onClick}
      className={`h-[52px] bg-[#e5e7eb] px-4 flex justify-start items-center gap-2 cursor-pointer rounded-[8px] mx-6`}
    >
      <span className="font-bold">{text}</span>
      {changeIcon ? <FaChevronDown /> : <FaChevronRight />}
    </div>
  );
};
