import { createSlice } from "@reduxjs/toolkit";

export const SettingsSlice = createSlice({
  name: "SettingsSlice",
  initialState: {
    outsideJob: "",
    type: "",
    parentCategory: null,
    formData: "",
    clientsIssues: [],
    subClientIssues: [],
    superSubClientIssues: [],
    superSubOptionsClientIssues: [],
    activeLayerOne: [],
    activeLayerTwo: [],
    activeLayerThree: [],
    activeLayerFour: [],
  },
  reducers: {
    updateOutsideJobLink: (state, actions) => {
      if (actions.payload) {
        state.outsideJob = actions?.payload;
      }
    },

    selectedType: (state, actions) => {
      if (actions.payload) {
        state.type = actions.payload;
      }
    },

    parentCategory: (state, actions) => {

      if (actions.payload) {
        state.parentCategory = actions.payload;
      }
    },
    addClientIssue: (state, actions) => {
      if (actions.payload) {
        let data = [];

        if (Array.isArray(actions.payload)) {
          data = actions.payload;
        } else {
          // Check if the payload already exists by matching `id`
          data = state.clientsIssues.map((issue) =>
            issue.id === actions.payload.id
              ? { ...issue, name: actions.payload.name } // Update name if `id` matches
              : issue
          );

          // If no match is found, append the new payload to the list
          if (!data.some((issue) => issue.id === actions.payload.id)) {
            data = [...state.clientsIssues, actions.payload];
          }
        }

        state.clientsIssues = data;
      }
    },
    addSubClientIssue: (state, actions) => {
      if (actions.payload) {
        let data = [];

        if (Array.isArray(actions.payload)) {
          data = actions.payload;
          // state.superSubClientIssues = [];
          // state.superSubOptionsClientIssues = [];

          // Preserve active tabs
          if (state.activeLayerTwo?.length > 0) {
            let activeTabs = [];
            actions.payload?.forEach((element) => {
              if (state.activeLayerTwo?.includes(element?.name)) {
                activeTabs.push(element?.name);
              }
            });
            state.activeLayerTwo = activeTabs;
          }
        } else {
          // Check if the payload already exists by matching `id`
          data = state.subClientIssues.map((issue) =>
            issue.id === actions.payload.id
              ? { ...issue, name: actions.payload.name } // Update name if `id` matches
              : issue
          );

          // If no match is found, append the new payload to the list
          if (!data.some((issue) => issue.id === actions.payload.id)) {
            data = [...state.subClientIssues, actions.payload];
          }
        }

        state.subClientIssues = data;
      }
    },

    // addSubClientIssue: (state, actions) => {
    //   if (actions.payload) {
    //     let data = [];
    //     if (Array.isArray(actions.payload)) {
    //       data = actions.payload;
    //       state.superSubClientIssues = [];
    //       state.superSubOptionsClientIssues = [];

    //       // pre active tabs
    //       if (state.activeLayerTwo?.length > 0) {
    //         let activeTabs = [];
    //         actions.payload?.forEach((element) => {
    //           if (state.activeLayerTwo?.includes(element?.name)) {
    //             activeTabs.push(element?.name);
    //           }
    //         });
    //         state.activeLayerTwo = activeTabs;
    //       }
    //     } else {
    //       data = [...state.subClientIssues, actions.payload];
    //     }
    //     state.subClientIssues = data;
    //   }
    // },

    // addSuperSubClientIssue: (state, actions) => {
    //   if (actions.payload) {
    //     let data = [];
    //     if (Array.isArray(actions.payload)) {
    //       data = actions.payload;
    //       state.superSubOptionsClientIssues = [];

    //       // pre active tabs
    //       if (state.activeLayerThree?.length > 0) {
    //         let activeTabs = [];
    //         actions.payload?.forEach((element) => {
    //           if (state.activeLayerThree?.includes(element?.name)) {
    //             activeTabs.push(element?.name);
    //           }
    //         });
    //         state.activeLayerThree = activeTabs;
    //       }
    //     } else {
    //       data = [...state.superSubClientIssues, actions.payload];
    //     }
    //     state.superSubClientIssues = data;
    //   }
    // },

    addSuperSubClientIssue: (state, actions) => {
      if (actions.payload) {
        let data = [];

        if (Array.isArray(actions.payload)) {
          data = actions.payload;
          // state.superSubOptionsClientIssues = [];

          // Preserve active tabs
          if (state.activeLayerThree?.length > 0) {
            let activeTabs = [];
            actions.payload?.forEach((element) => {
              if (state.activeLayerThree?.includes(element?.name)) {
                activeTabs.push(element?.name);
              }
            });
            state.activeLayerThree = activeTabs;
          }
        } else {
          // Check if the payload already exists by matching `id`
          data = state.superSubClientIssues.map((issue) =>
            issue.id === actions.payload.id
              ? { ...issue, name: actions.payload.name } // Update name if `id` matches
              : issue
          );

          // If no match is found, append the new payload to the list
          if (!data.some((issue) => issue.id === actions.payload.id)) {
            data = [...state.superSubClientIssues, actions.payload];
          }
        }
        state.superSubClientIssues = data;
      }
    },

    // addSuperSubOptionClientIssue: (state, actions) => {
    //   if (actions.payload) {
    //     let data = [];
    //     if (Array.isArray(actions.payload)) {
    //       data = actions.payload;

    //       // pre active tabs
    //       if (state.activeLayerFour?.length > 0) {
    //         let activeTabs = [];
    //         actions.payload?.forEach((element) => {
    //           if (state.activeLayerFour?.includes(element?.name)) {
    //             activeTabs.push(element?.name);
    //           }
    //         });
    //         state.activeLayerFour = activeTabs;
    //       }
    //     } else {
    //       data = [...state.superSubOptionsClientIssues, actions.payload];
    //     }
    //     state.superSubOptionsClientIssues = data;
    //   }
    // },

    addSuperSubOptionClientIssue: (state, actions) => {
      if (actions.payload) {
        let data = [];

        if (Array.isArray(actions.payload)) {
          data = actions.payload;

          // Preserve active tabs
          if (state.activeLayerFour?.length > 0) {
            let activeTabs = [];
            actions.payload?.forEach((element) => {
              if (state.activeLayerFour?.includes(element?.name)) {
                activeTabs.push(element?.name);
              }
            });
            state.activeLayerFour = activeTabs;
          }
        } else {
          // Check if the payload already exists by matching `id`
          data = state.superSubOptionsClientIssues.map((issue) =>
            issue.id === actions.payload.id
              ? { ...issue, name: actions.payload.name } // Update name if `id` matches
              : issue
          );

          // If no match is found, append the new payload to the list
          if (!data.some((issue) => issue.id === actions.payload.id)) {
            data = [...state.superSubOptionsClientIssues, actions.payload];
          }
        }

        state.superSubOptionsClientIssues = data;
      }
    },

    manageForm: (state, actions) => {
      if (actions.payload) {
        state.formData = actions.payload;
      }
    },

    updateLayerOne: (state, actions) => {
      if (actions.payload) {
        if (Array?.isArray(actions?.payload)) {
          state.activeLayerOne = actions?.payload;
          return;
        } else {
          if (state.activeLayerOne?.find((name) => name === actions.payload)) {
            const updatedArr = state.activeLayerOne?.filter(
              (ele) => ele != actions.payload
            );
            state.activeLayerOne = updatedArr;
          } else {
            // const updatedArr = [...state.activeLayerOne, actions.payload];
            state.activeLayerOne = [actions.payload];
          }
        }
      }
    },

    updateLayerTwo: (state, actions) => {
      if (!Array.isArray(actions.payload)) {
        if (state.activeLayerTwo?.find((name) => name === actions.payload)) {
          const updatedArr = state.activeLayerTwo?.filter(
            (ele) => ele != actions.payload
          );
          state.activeLayerTwo = updatedArr;
        } else {
          // const updatedArr = [...state.activeLayerTwo, actions.payload];
          state.activeLayerTwo = [actions.payload];
        }
      } else {
        state.activeLayerTwo = actions.payload;
      }
    },

    updateLayerThree: (state, actions) => {
      if (actions.payload) {
        if (Array?.isArray(actions?.payload)) {
          state.activeLayerThree = actions?.payload;
          return;
        }
        if (state.activeLayerThree?.find((name) => name === actions.payload)) {
          const updatedArr = state.activeLayerThree?.filter(
            (ele) => ele != actions.payload
          );
          state.activeLayerThree = updatedArr;
        } else {
          // const updatedArr = [...state.activeLayerThree, actions.payload];
          // state.activeLayerThree = updatedArr;
          state.activeLayerThree = [actions.payload];
        }
      }
    },

    updateLayerfour: (state, actions) => {
      if (actions.payload) {
        if (Array?.isArray(actions?.payload)) {
          state.activeLayerFour = actions?.payload;
          return;
        }
        if (state.activeLayerFour?.find((name) => name === actions.payload)) {
          const updatedArr = state.activeLayerFour?.filter(
            (ele) => ele != actions.payload
          );
          state.activeLayerFour = updatedArr;
        } else {
          // const updatedArr = [...state.activeLayerFour, actions.payload];
          state.activeLayerFour = [actions.payload];
        }
      }
    },

    resetStmateSlice: (state, action) => {
      if (action) {
        state.outsideJob = "";
        state.type = "";
        state.clientsIssues = [];
      }
    },
    resetAllActivebtns: (state, action) => {
      if (action) {
        state.subClientIssues = [];
        state.superSubClientIssues = [];
        state.superSubOptionsClientIssues = [];
        state.activeLayerOne = [];
        state.activeLayerTwo = [];
        state.activeLayerThree = [];
        state.activeLayerFour = [];
      }
    },
  },
});

export const {
  updateOutsideJobLink,
  selectedType,
  addClientIssue,
  addSubClientIssue,
  addSuperSubClientIssue,
  addSuperSubOptionClientIssue,
  resetStmateSlice,
  manageForm,
  updateLayerOne,
  updateLayerTwo,
  updateLayerThree,
  updateLayerfour,
  resetAllActivebtns,
  parentCategory,
} = SettingsSlice.actions;
export default SettingsSlice.reducer;
