import React from "react";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <div className="w-full h-dvh flex bg-[#f6f9fc]">
      <div className="w-full bg-blue-300 max-md:hidden relative">
        <div className="absolute inset-0 bg-[#0000004f]">

        </div>
        <img
          src="https://trioheatingandair.com/wp-content/uploads/2024/02/IMG_1420.jpg"
          alt="logo"
          className="w-full h-full"
        />
      </div>
      <div className="w-full h-full overflow-y-auto px-10">
        <Outlet />
      </div>
    </div>
  );
};

export default PublicLayout;
