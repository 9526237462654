import AuthForm from "components/AuthForm";
import React, { useState } from "react";
import { loginData } from "./data";
import { authLogin, sendOtp } from "../services/Collections";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SignInStep } from "store/slices/SignInSlice";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSendOtp = async (email) => {
    let requestPayload = {
      email: email,
    };
    let res = await sendOtp(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      toast.success("OTP sent to your email");
    } else {
      // toast.error(
      //   res?.response?.data?.message || res?.message || "Something went wrong"
      // );
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let requestPayload = {
      ...values,
      device: "web",
    };
    let res = await authLogin(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      if (res?.data?.set_password_require) {
        navigate("/change-password", { state: { id: res?.data?.id } });
        return;
      }
      if (res?.data?.verify === 0) {
        await handleSendOtp(values?.email);
        navigate("/congratulation", { state: { email: values?.email } });
        setLoading(false);
      } else if (!res?.data?.workspaces) {
        navigate("/step-1");
        setLoading(false);
      } else {
        dispatch(SignInStep(res?.data));
        setLoading(false);
      }
      // navigate("/step-1");
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  return (
    <div className="authFormWrapper">
      <AuthForm loading={loading} onFinish={handleSubmit} data={loginData} />
    </div>
  );
};

export default Login;
