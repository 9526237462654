import React, { useState } from "react";
import { Answers, Heading, Stepsection } from "./Stepsstyle";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { ButtonComp } from "components/ButtonComp";
import { createWorkspace } from "../../services/Collections";
import { toast } from "react-toastify";

export default function Step1() {
  const [teamName, setTeamName] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    if (teamName?.trim() !== "") {
      if (teamName?.trim()?.length < 3) {
        toast.warning("Team name should be atleast 3 characters long");
      } else {
        navigate("/step-2", { state: { teamName: teamName?.trim() } });
      }
    } else {
      toast.warning("Please enter team name");
    }
  };

  const handleTeamName = (e) => {
    setTeamName(e.target.value);
  };

  return (
    <div className="w-full h-full flex items-center">
      <div className="w-full">
        <Heading>
          <p className="stepstage">STEP 1 OF 2</p>
          <p className="heading"> What’s the name of your team or company</p>
          <p className="lighttext">
            {" "}
            This will be the name of your trio workspace- choose something that
            your team will recognise
          </p>
        </Heading>
        <Answers>
          <div className="field">
            <div className="team">
              <p className="company"> TEAM OR COMPANY NAME</p>
              <p className="number">{teamName?.length}/50</p>
            </div>
            <div>
              <input
                className="input-field"
                placeholder="Enter a name"
                name="team-name"
                onChange={handleTeamName}
                maxLength={50}
              />
            </div>
            <ButtonComp
              textcard={"NEXT"}
              isLoading={false}
              handleAction={() => handleNext()}
            />
          </div>
        </Answers>
      </div>
    </div>
  );
}
