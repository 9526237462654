import { deleteApi, getApi, postApi, putApi } from "./ApiMethods";

const AUTH_LOGIN = "users/login";
const CREATE_ACCOUNT = "users";
const VERIFY_EMAIL = "users/auth/opt/validation";
const FORGOT_PASSWORD = "users/auth/forgot-password";
const CREATE_WORKSPACE = "stmate/workspaces";
const SEND_OTP = "users/auth/opt";
const SET_NEW_PASSWORD = "users/auth/reset-password";
const GET_BUSINESS_LIST = "business-units";
const GET_JOBTYPE_LIST = "job-types";
const GET_USERS_LIST = "users";
const SEARCHING_JOBS = "searching-jobs";
const CATEGORIES = "categories";
const ISSUES = "issues";
const GET_MANAGER = "users/managers";
const GET_ALL_MANAGERS_LIST = "users/all";
const MARK_AS_MANAGERS = "managers";
const ASSIGN_MANAGER = "users";
const DELETE_CATEGORY = "categories";
const ADD_CATEGORY = "categories";
const ALL_CATEGORY = "categories/all";
const ISSUES_OPTIONS = "issues/options";
const CHANGE_PASSWORD = "users/change-password";
const ISSUES_SUMMARY = "issues/summary";
const JobType = "job-types/jobs";
const USER_HISTORY = "estimate/history";

export const authLogin = (payload) => postApi(AUTH_LOGIN, payload);

export const createAccount = (payload) => postApi(CREATE_ACCOUNT, payload);

export const verifyEmail = (payload) => postApi(VERIFY_EMAIL, payload);

export const forgotPassword = (payload) => postApi(FORGOT_PASSWORD, payload);

export const createWorkspace = (payload) => postApi(CREATE_WORKSPACE, payload);

export const sendOtp = (payload) => postApi(SEND_OTP, payload);

export const setNewPassword = (payload) => postApi(SET_NEW_PASSWORD, payload);

export const getBusinessListing = (key) =>
  getApi(`stmate/${key}/${GET_BUSINESS_LIST}?key=${key}`);

export const getJobTypeListing = (key) =>
  getApi(`stmate/${key}/${GET_JOBTYPE_LIST}?key=${key}`);

export const getUsersList = (key) =>
  getApi(`stmate/${key}/${GET_USERS_LIST}?key=${key}`);

export const searchJob = (key) => getApi(`stmate/${SEARCHING_JOBS}?${key}`);

export const getCategories = (key, id) => {
  if (id) {
    return getApi(`stmate/${key}/${CATEGORIES}?${id}`);
  }
  return getApi(`stmate/${key}/${CATEGORIES}?key=${key}`);
};

export const categoriesIssues = (key, payload) =>
  postApi(`stmate/${key}/${ISSUES}`, payload);

export const updateJobType = (key, id, payload) => {
  return putApi(`stmate/${key}/${GET_JOBTYPE_LIST}/${id}?key=${key}`, payload);
};

export const getManagers = (key) =>
  getApi(`stmate/${key}/${GET_MANAGER}?key=${key}`);

export const getAllManagersList = (key) =>
  getApi(`stmate/${key}/${GET_ALL_MANAGERS_LIST}?key=${key}`);

export const markAsManagers = (key, id, payload) =>
  putApi(`stmate/${key}/users/${id}/${MARK_AS_MANAGERS}?key=${key}`, payload);

export const assignManager = (key, id, payload) =>
  putApi(`stmate/${key}/${ASSIGN_MANAGER}/${id}`, payload);

export const deleteIssue = (key, id) =>
  deleteApi(`stmate/${key}/${DELETE_CATEGORY}/${id}`);

export const addCategory = (key, payload) =>
  postApi(`stmate/${key}/${ADD_CATEGORY}`, payload);

export const updateCategory = (key, id, payload) =>
  putApi(`stmate/${key}/${ADD_CATEGORY}/${id}`, payload);

export const replaceCategories = (key, id, payload) =>
  putApi(`stmate/${key}/${ADD_CATEGORY}/${id}`, payload);

export const getAllCategories = (key) =>
  getApi(`stmate/${key}/${ALL_CATEGORY}`);

export const getIssuesOptions = (key, param) =>
  getApi(`stmate/${key}/${ISSUES_OPTIONS}?${param}`);

export const pushIssues = (key, payload) =>
  postApi(`stmate/${key}/${ISSUES_OPTIONS}?key=${key}`, payload);

export const DeletePremium = (key, option, param) =>
  deleteApi(`stmate/${key}/${ISSUES_OPTIONS}/${option}?${param}`);

export const ChangePasswordApi = (payload) =>
  postApi(`${CHANGE_PASSWORD}`, payload);

export const JobtypeBeforeSubmit = (key, job) =>
  getApi(`stmate/${key}/${JobType}/${job}`);

export const SubmitSummary = (key, payload) =>
  postApi(`stmate/${key}/${ISSUES_SUMMARY}`, payload);

export const PushHistoryToSt = (key, payload) =>
  postApi(`stmate/${key}/${USER_HISTORY}/`, payload);

export const UpdatePushTOSt = (key, id, payload) =>
  putApi(`stmate/${key}/${USER_HISTORY}/${id}`, payload);
