import Api from "./Interceptor";

//get Api
export const getApi = async (url) => {
  try {
    let result = await Api.get(url);
    if (result.status === 200) {
      if (!result?.data?.status) return { data: result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200 || result?.data?.message == "OK") {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

//post Api
export const postApi = async (url, data) => {
  try {
    let result = await Api.post(url, data);
    if (result.status === 200 || result.status === 201) {
      if (!result?.data?.status) return { ...result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200 || result?.data?.message == "OK") {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

//put Api
export const putApi = async (url, data) => {
  try {
    let result = await Api.put(url, data);
    if (result.status === 200) {
      if (!result?.data?.status) return { ...result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200) {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

//delete Api
export const deleteApi = async (url, data) => {
  try {
    let result = await Api.delete(url, { params: data });
    if (result.status === 200) {
      if (!result?.data?.status) return { ...result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200) {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

export const patchApi = async (url, data) => {
  try {
    let result = await Api.patch(url, data);
    if (result.status === 200) {
      return { ...result.data, status: 200 };
    } else {
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};
