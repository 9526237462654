import React from "react";
import OTPInput from "react-otp-input";

export default function Otp({ otp, setOtp, inputStyle }) {
  return (
    <OTPInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span style={{ margin: "0 8px"}}></span>}
      renderInput={(props) => <input {...props} type="tel"/>}
      inputStyle={inputStyle}
      inputType="number"
    />
  );
}
