import {
  PushHistoryToSt,
  SubmitSummary,
  UpdatePushTOSt,
} from "services/Collections";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "components/Loader";
import { Input, Modal } from "antd";
import { groupByPriority } from "utils/Functions";
import TextArea from "antd/es/input/TextArea";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ServiceItem from "./ServiceItem";
import { styled } from "styled-components";
import Header from "./Header";
import { TestDiv } from "./SubHeader";
import { manageForm } from "store/slices/StmateSlice";
import { useDispatch } from "react-redux";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

export default function Form() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pushLoader, setPushLoader] = useState(false);
  const navigate = useNavigate();
  const stmateData = useSelector((state) => state?.STMate);
  const { formData } = stmateData;
  const loginData = useSelector((state) => state?.LoginSlice?.data);
  const workspace = loginData?.workspaces?.[0];
  const [modalLoading, setModalLoading] = useState(null);
  const [addModal, setAddModal] = useState(null);
  const [addedit, setddedit] = useState(null);
  const [addData, setAddData] = useState({
    name: "",
    summary: "",
    addresseshealthissue: false,
    urgency: null,
  });
  const [editData, setEditData] = useState({
    name: "",
    summary: "",
  });

  const currentActiveJob = useSelector((state) => state?.STMate?.outsideJob);
  const [activePackage, setActivePackage] = useState(null);
  const [name, setName] = useState("");
  const inputPackageNameRef = useRef(null);
  const [textField, setTextFiled] = useState("");
  const textAreaRef = useRef(null);
  const inputRef = useRef(null);
  const [header, setHeader] = useState(false);
  const headerData = useSelector((state) => state?.STMate?.header);
  const [inputHeight, setInputHeight] = useState(0);
  const [summaryTitle, setSummatyTitle] = useState("");
  const [versionIndex, setVersionIndex] = useState(0);
  const summaryTitleRef = useRef(null);

  const adjustHeight = () => {
    const textarea = inputPackageNameRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea?.scrollHeight, 100)}px`;
    }
    setInputHeight(Math.min(textarea?.scrollHeight, 100));
  };

  const handleInput = (event) => {
    setName(event.target.value);
    adjustHeight(); // Adjust height as user types or pastes
  };

  const handleSelectTextArea = () => {
    if (textField?.trim() !== "" && textAreaRef.current) {
      textAreaRef.current.select();
    }
  };

  const AutoSelectTitle = () => {
    if (summaryTitleRef.current) {
      summaryTitleRef.current.select();
    }
  };

  const handleAddInput = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;

    setAddData((pre) => ({ ...pre, [name]: value, urgency: addModal }));
  };
  const handleEditInput = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;

    setEditData((pre) => ({ ...pre, [name]: value }));
  };

  const handleCancel = () => {
    setAddModal(null);
    setddedit(false);
    setAddData({
      name: "",
      summary: "",
      addresseshealthissue: false,
      urgency: null,
    });
    setEditData({
      name: "",
      summary: "",
    });
    setModalLoading(false);
  };

  const handleAddModal = () => {
    setModalLoading(true);
    setData(
      (prev) =>
        prev && {
          Summary: prev?.Summary,
          RecommendedServices: prev?.RecommendedServices?.filter((el) =>
            el?.priority === addData?.urgency ? el?.child?.push(addData) : el
          ),
        }
    );
    setAddModal(null);
    setModalLoading(false);
    setAddData({
      name: "",
      summary: "",
      addresseshealthissue: false,
      urgency: null,
    });
  };

  const handleEditModal = () => {
    setModalLoading(true);
    const updated = data?.RecommendedServices.map((ele, index) => {
      if (index === addedit?.parentIndex) {
        return {
          ...ele,
          child: ele?.child?.map((val, idx) => {
            if (idx === addedit?.childIndex) {
              return {
                ...val,
                name: editData?.name,
                summary: editData?.summary,
              };
            }
            return val;
          }),
        };
      }
      return ele;
    });

    let finalObj = {
      Summary: data?.Summary,
      RecommendedServices: updated,
    };
    setData(finalObj);
    setddedit(null);
    setModalLoading(false);
    setEditData({
      name: "",
      summary: "",
    });
  };

  const handleDeletModal = (parentIndex, childIndex) => {
    const updated = data?.RecommendedServices.map((ele, index) => {
      if (index === parentIndex) {
        return {
          ...ele,
          child: ele?.child?.filter((val, idx) => {
            if (idx !== childIndex) {
              return val;
            }
          }),
        };
      }
      return ele;
    });

    let finalObj = {
      Summary: data?.Summary,
      RecommendedServices: updated,
    };
    setData(finalObj);
  };

  const handleChecked = (checked, parentIndex, childIndex) => {
    const updated = data?.RecommendedServices.map((ele, index) => {
      if (index === parentIndex) {
        return {
          ...ele,
          child: ele?.child?.map((val, idx) => {
            if (idx === childIndex) {
              return { ...val, addresseshealthissue: checked };
            }
            return val;
          }),
        };
      }
      return ele;
    });

    let finalObj = {
      Summary: data?.Summary,
      RecommendedServices: updated,
    };
    setData(finalObj);
  };

  const handleActivePackage = (history) => {
    if (history?.id !== activePackage?.id) {
      setActivePackage(history);
      setData({ Summary: history?.summary, RecommendedServices: [] });
      setTextFiled(history?.summary);
    } else {
      setActivePackage(null);
      setData({
        Summary: formData?.[formData?.length - 1]?.Summary,
        RecommendedServices: [],
      });
      setTextFiled(formData?.[formData?.length - 1]?.Summary);
    }
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // If no destination (dropped outside droppable)
    if (!destination) return;

    // Avoid unnecessary drag/drop in the same position
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    // Find the source and destination priorities
    const sourcePriorityIndex = data.RecommendedServices.findIndex(
      (p) => p.priority.toString() === source.droppableId
    );
    const destinationPriorityIndex = data.RecommendedServices.findIndex(
      (p) => p.priority.toString() === destination.droppableId
    );

    // Make a shallow copy of data to avoid mutating the original state
    const updatedData = JSON.parse(JSON.stringify(data));

    // Get the source and destination child arrays
    const sourceChildren =
      updatedData.RecommendedServices[sourcePriorityIndex].child;
    const destinationChildren =
      updatedData.RecommendedServices[destinationPriorityIndex].child;
    const destinationPriority =
      updatedData.RecommendedServices[destinationPriorityIndex].priority;

    // Remove the dragged item from the source array
    const [movedItem] = sourceChildren.splice(source.index, 1);

    // Insert the dragged item into the destination array
    destinationChildren.splice(destination.index, 0, {
      ...movedItem,
      urgency: destinationPriority,
    });

    setData(updatedData);
  };

  const handleReWriteSummary = async () => {
    if (name == "" || name == undefined || name?.trim()?.length == 0) {
      toast.error("Please Add Instructions");
      return;
    }
    if (
      textField == "" ||
      textField == undefined ||
      textField?.trim()?.length == 0
    ) {
      toast.error("Please Enter Summary");
      return;
    }
    setLoading(true);
    const dataObj = {
      job: currentActiveJob,
      summary: textField,
      prompt: name,
    };

    const res = await SubmitSummary(workspace?.key, dataObj);
    console.log("Instruction GPT Response: ", res);
    if (res?.status == 200) {
      let newSummary = {
        Summary: res?.data?.ai_response,
        oldSummary: res?.data?.ai_response,
        RecommendedServices: [],
        buttonStatus: "Push to Service Titan",
        title: "",
        oldTitle: "",
      };
      const updateFormData = [...formData, newSummary];
      dispatch(manageForm(updateFormData));
      setName("");
      setTextFiled(res?.data?.ai_response);
      setVersionIndex(formData?.length);
      setSummatyTitle("");
      setData(newSummary);

      toast.success("Summary Re-write Successfully.", {
        style: {
          color: "black",
        },
      });
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
    setLoading(false);
  };

  const handlePushToSt = async (text) => {
    if (
      textField == "" ||
      textField == undefined ||
      textField?.trim()?.length == 0
    ) {
      toast.error("Please Enter Summary");
      return;
    }
    if (
      summaryTitle == "" ||
      summaryTitle == undefined ||
      summaryTitle?.trim()?.length == 0
    ) {
      toast.error("Please Enter Title");
      return;
    }
    setPushLoader(true);
    let res;
    if (text == "Update Service Titan") {
      let obj = {
        title: summaryTitle,
        summary: textField,
      };
      res = await UpdatePushTOSt(
        workspace?.key,
        formData[versionIndex]?.id,
        obj
      );
    } else {
      let obj = {
        user_id: loginData?.id,
        job_id: currentActiveJob,
        title: summaryTitle,
        summary: textField,
      };
      res = await PushHistoryToSt(workspace?.key, obj);
    }

    if (res?.status == 200 || res?.status == 201) {
      const cloneFormData = [...formData];
      let updateData = {
        oldSummary: textField,
        oldTitle: summaryTitle,
        buttonStatus: "Pushed to Service Titan",
        id: res?.data?.id,
      };
      let updateFormData = cloneFormData?.map((ele, i) =>
        i == versionIndex ? { ...ele, ...updateData } : ele
      );
      dispatch(manageForm(updateFormData));
      setData((pre) => ({ ...pre, ...updateData }));

      let toastMessage = text == "Update Service Titan" ? "Updated" : "Push";
      toast.success(
        `${toastMessage || "Push"} to Service Titan Successfully.`,
        {
          style: {
            color: "black",
          },
        }
      );
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
    setPushLoader(false);
  };

  const handleVersionSummary = (index) => {
    const updateFormData = [...formData];
    let ActiveSummary = updateFormData?.[index];
    setName("");
    setData(ActiveSummary);
    setSummatyTitle(ActiveSummary?.title);
    setTextFiled(ActiveSummary?.Summary);
  };

  const updateTitle = (value) => {
    setSummatyTitle(value);

    let formObj = formData?.[versionIndex];
    let buttonText = "Push to Service Titan";
    if (formObj?.id) {
      if (formObj?.oldTitle?.trim() == value?.trim()) {
        buttonText = "Pushed to Service Titan";
      } else {
        buttonText = "Update Service Titan";
      }
    }

    const cloneFormData = [...formData];
    let updateFormData = cloneFormData?.map((ele, i) =>
      i == versionIndex
        ? { ...ele, buttonStatus: buttonText, title: value }
        : ele
    );
    dispatch(manageForm(updateFormData));
    setData((pre) => ({
      ...pre,
      buttonStatus: buttonText,
      title: value,
    }));
  };

  const updateSummary = (value) => {
    setTextFiled(value);

    let formObj = formData?.[versionIndex];
    let buttonText = "Push to Service Titan";
    if (formObj?.id) {
      if (formObj?.oldTitle?.trim() == value?.trim()) {
        buttonText = "Pushed to Service Titan";
      } else {
        buttonText = "Update Service Titan";
      }
    }

    const cloneFormData = [...formData];
    let updateFormData = cloneFormData?.map((ele, i) =>
      i == versionIndex
        ? { ...ele, buttonStatus: buttonText, Summary: value }
        : ele
    );
    dispatch(manageForm(updateFormData));
    setData((pre) => ({
      ...pre,
      buttonStatus: buttonText,
      Summary: value,
    }));
  };

  // useEffect(() => {
  //   if (addModal && inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [addModal]);

  useEffect(() => {
    setData(formData?.[versionIndex] || {});
    setName("");
    setTextFiled(formData?.[versionIndex]?.Summary || "");
    setSummatyTitle(formData?.[versionIndex]?.title || "");
    setVersionIndex(0);
  }, []);

  useEffect(() => {
    adjustHeight(); // Adjust height on initial render and when value changes
  }, [name]);

  const handlePushBtn = () => {
    let formObj = formData?.[versionIndex];
    let buttonText = "Push to Service Titan";
    if (formObj?.id) {
      if (
        formObj?.oldTitle?.trim() == summaryTitle?.trim() &&
        formObj?.oldSummary?.trim() == textField?.trim()
      ) {
        buttonText = "Pushed to Service Titan";
      } else {
        buttonText = "Update Service Titan";
      }
    }
    return buttonText;
  };
  const PushToStStauts = useMemo(
    () => handlePushBtn(),
    [textField, summaryTitle, formData[versionIndex]]
  );

  if (loading || data == null) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader size={34} color={"#000"} />
      </div>
    );
  }

  return (
    <>
      <TestDiv>
        <Header
          jobDetails={headerData?.data}
          header={header}
          setHeader={setHeader}
        />
        <div className="w-full py-2 bg-[#d9d9d9] flex justify-between items-center px-[15px]">
          <h1
            onClick={() => navigate(-1)}
            className="text-base font-semibold cursor-pointer"
          >
            {"< Form"}
          </h1>
          <ButtonStyle
            active={PushToStStauts == "Pushed to Service Titan" ? false : true}
            disabled={
              PushToStStauts == "Pushed to Service Titan" ? true : false
            }
            btnStyle={
              PushToStStauts == "Pushed to Service Titan" && {
                color: "#cccccc",
                background: "#ffffff",
              }
            }
            onClick={() => handlePushToSt(PushToStStauts)}
          >
            {pushLoader ? (
              <Loader color="#ffffff" size="20px" />
            ) : (
              PushToStStauts
            )}
          </ButtonStyle>
        </div>
      </TestDiv>
      <div className="w-full mx-auto px-4 bg-[#f6f6f6] rounded-lg">
        <div className="flex gap-6">
          <div className="w-full col-span-2 mt-5 mx-3 flex flex-col">
            <InputFieldWrapper className="mt-2">
              <div className="input-wrap">
                <InputTextAreaStyle
                  ref={inputPackageNameRef}
                  placeholder="Add instructions"
                  type="text"
                  value={name}
                  rows={1}
                  onInput={handleInput}
                  style={{
                    overflow: "auto",
                    resize: "none",
                    width: "100%",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <ButtonStyle
                active={name?.trim()?.length > 0}
                onClick={() => {
                  name?.trim()?.length > 0 && handleReWriteSummary();
                }}
                btnStyle={{
                  background: "transparent",
                  color: "#9ca3af",
                }}
              >
                Re-write Summary
              </ButtonStyle>
            </InputFieldWrapper>

            <div className="rounded-[20px] pt-4 mb-4">
              <div className="flex justify-between items-center mb-4">
                <p className="m-0 font-semibold text-[20px]">Summary</p>

                <div className="flex gap-3">
                  {/* <ButtonStyle
                    onClick={() => handleSelectTextArea()}
                    style={{
                      padding: "2px 10px",
                      margin: "10px 0 0 0",
                      background: "transparent",
                      border: "1px solid #c3c5c9",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    Select All
                  </ButtonStyle> */}
                  <VersionStyleTab>
                    <button
                      onClick={() =>
                        versionIndex != 0 &&
                        (setVersionIndex((pre) => pre - 1),
                        handleVersionSummary(versionIndex - 1))
                      }
                      style={{ justifyContent: "end" }}
                    >
                      {versionIndex !== 0 && (
                        <MdArrowBackIos
                          style={{
                            cursor: versionIndex == 0 ? "default" : "pointer",
                          }}
                        />
                      )}
                    </button>

                    <div>
                      Version {versionIndex + 1} of {formData?.length}
                    </div>

                    <button
                      onClick={() =>
                        versionIndex != formData?.length - 1 &&
                        (setVersionIndex((pre) => pre + 1),
                        handleVersionSummary(versionIndex + 1))
                      }
                      style={{ justifyContent: "start" }}
                    >
                      {versionIndex !== formData?.length - 1 && (
                        <MdArrowForwardIos
                          style={{
                            cursor:
                              versionIndex == formData?.length - 1
                                ? "default"
                                : "pointer",
                          }}
                        />
                      )}
                    </button>
                  </VersionStyleTab>
                </div>
              </div>
              <div className="flex items-starte gap-3 mb-3">
                <p className="text-base font-semibold">Title</p>
                <TextAreaStyle
                  ref={summaryTitleRef}
                  onFocus={AutoSelectTitle}
                  style={{ padding: "10px" }}
                  height="46px"
                  placeholder="Enter title"
                  value={summaryTitle}
                  onChange={(e) => updateTitle(e.target.value)}
                />
              </div>
              <TextAreaStyle
                height={`calc(100vh - ${348 + (inputHeight || 50)}px)`}
                ref={textAreaRef}
                placeholder="Enter Summary"
                value={textField}
                onChange={(e) => updateSummary(e.target.value)}
              />
            </div>

            {/* <DragDropContext onDragEnd={handleOnDragEnd}>
              {data?.RecommendedServices?.map((priorityGroup, preIdx) => (
                <Droppable
                  droppableId={priorityGroup.priority.toString()}
                  key={priorityGroup.priority}
                >
                  {(provided) => (
                    <div
                      className="p-4 rounded-lg mb-6 capitalize"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div className="bg-[#e7e7e7] flex justify-between items-center mb-2 py-[8px] px-3">
                        <span className="font-medium text-base">
                          Priority {priorityGroup?.priority || ""} Services
                        </span>
                        <button
                          onClick={() => setAddModal(priorityGroup?.priority)}
                          className="border border-black px-2 rounded-[20px] flex items-center justify-center"
                        >
                          +<span>add</span>
                        </button>
                      </div>
                      <div className="border border-black space-y-2 rounded-[16px] overflow-hidden">
                        {priorityGroup?.child?.map((child, index) => (
                          <Draggable
                            key={child.name}
                            draggableId={`${priorityGroup.priority}-${child.name}`}
                            index={index}
                          >
                            {(provided) => (
                              <div ref={provided.innerRef}>
                                <ServiceItem
                                  service={child?.name}
                                  description={child?.summary}
                                  onRemove={child?.addresseshealthissue}
                                  onhandleCheck={handleChecked}
                                  parentIndex={preIdx}
                                  childIndex={index}
                                  setddedit={setddedit}
                                  setEditData={setEditData}
                                  deleteModal={handleDeletModal}
                                  draggableProps={provided.draggableProps}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext> */}
          </div>
          {addModal && (
            <Modal
              open={addModal ? true : false}
              title="Add Service"
              onCancel={handleCancel}
              onOk={handleAddModal}
              confirmLoading={modalLoading}
            >
              <Input
                ref={inputRef}
                autoFocus
                name="name"
                placeholder="Enter service"
                value={addData?.name}
                onChange={(e) => handleAddInput(e)}
                minLength={1}
                maxLength={50}
                className="my-2"
              />
              <TextArea
                name="summary"
                placeholder="Enter description"
                value={addData?.summary}
                onChange={(e) => handleAddInput(e)}
                minLength={1}
                maxLength={250}
                className="my-2"
              />
            </Modal>
          )}
          {addedit && (
            <Modal
              open={addedit ? true : false}
              title="Edit Service"
              onCancel={handleCancel}
              onOk={handleEditModal}
              confirmLoading={modalLoading}
            >
              <Input
                name="name"
                placeholder="Enter service"
                value={editData?.name}
                onChange={(e) => handleEditInput(e)}
                minLength={1}
                maxLength={50}
                className="my-2"
              />
              <TextArea
                name="summary"
                placeholder="Enter description"
                value={editData?.summary}
                onChange={(e) => handleEditInput(e)}
                minLength={1}
                maxLength={250}
                className="my-2"
              />
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}

export const ButtonStyle = styled.button`
  background: ${({ btnStyle, active }) =>
    active ? "#3a7bc2" : btnStyle?.background};
  color: ${({ btnStyle, active }) => (active ? "#fff" : btnStyle?.color)};
  padding: ${({ btnStyle }) => btnStyle?.padding || "10px 20px"};
  margin: ${({ btnStyle }) => btnStyle?.margin || "0"};
  border-radius: ${({ btnStyle }) => btnStyle?.borderRadius || "6px"};
  border: ${({ btnStyle }) => btnStyle?.border || "1px solid transparent"};
  font-weight: 600;
  cursor: ${({ btnStyle, active }) => (active ? "pointer" : btnStyle?.cursor)};
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height || "calc(100vh - 110px)"};
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a4ab;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8e9299;
  }
`;

const TextAreaStyle = styled.textarea`
  width: 100%;
  height: ${({ height }) => height || "calc(100vh - 380px)"};
  resize: none;
  padding: 15px 20px;
  border: 1px solid #c3c5c9;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a4ab;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8e9299;
  }

  &:focus {
    outline: none;
  }
`;

const InputTextAreaStyle = styled.textarea`
  width: 100%;
  /* height: 95px; */
  resize: none;
  padding: 10px 20px;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a4ab;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8e9299;
  }

  &:focus {
    outline: none;
  }
`;

const InputFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  gap: 24px;

  .input-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    border: 1px solid #c4c7ca;
    background: #fff;
    border-radius: 8px;
  }
`;

const VersionStyleTab = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: #7d7d7d;

  button {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: default;
  }
`;
