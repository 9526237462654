import React from "react";
import styled from "styled-components";
import { Loader } from "./Loader";

export const ButtonComp = ({
  textcard,
  handleAction,
  isLoading,
  color,
  background,
}) => {
  return handleAction ? (
    <PublicButtonWrapper
      onClick={handleAction}
      color={color}
      background={background}
    >
      {isLoading ? (
        <Loader size={24} color={"#3D97F2"} />
      ) : (
        <button
          className="bg-primary-theme hover:bg-secondary-theme transition-colors"
          type="submit"
        >
          {textcard}
        </button>
      )}
    </PublicButtonWrapper>
  ) : (
    <PublicButtonWrapper color={color} background={background}>
      {isLoading ? (
        <Loader size={24} color={"#3D97F2"} />
      ) : (
        <button
          className="bg-primary-theme hover:bg-secondary-theme transition-colors"
          type="submit"
        >
          {textcard}
        </button>
      )}
    </PublicButtonWrapper>
  );
};

const PublicButtonWrapper = styled.div`
  width: 100%;
  button {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    /* background: ${({ background }) =>
      background ? background : "#3d97f2"}; */
    color: ${({ color }) => (color ? color : "#fff")};
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-style: none;
    letter-spacing: -0.01em;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.16);
    }
  }

  div {
    width: 100%;
    padding: 15px;
    background: transparent;
    border-radius: 8px;
    text-align: center;
    color: #ffff;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-style: none;
    letter-spacing: -0.01em;
    cursor: pointer;
  }
`;
