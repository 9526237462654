import { createSlice } from "@reduxjs/toolkit";

export const SignInSlice = createSlice({
  name: "SignInSlice",
  initialState: {
    data: null,
    sideNav: false,
    notificationTab: 1,
    rememberMe: null,
    verifyOtp: null,
    steps: 0,
  },
  reducers: {
    SignInStep: (state, actions) => {
      if (actions) {
        state.data = actions.payload;
      }
    },
    OpenSideNav: (state, action) => {
      if (action) {
        state.sideNav = action.payload;
      }
    },
    UpdateSteps: (state, action) => {
      if (action) {
        state.steps = action?.payload;
      }
    },
    SigninInitialStateReset: (state, actions) => {
      if (actions) {
        state.data = null;
        state.sideNav = false;
        state.notificationTab = 1;
        state.rememberMe = null;
        state.verifyOtp = null;
        state.steps = 0;
      }
    },
  },
});

export const { SignInStep, OpenSideNav, UpdateSteps, SigninInitialStateReset } =
  SignInSlice.actions;
export default SignInSlice.reducer;
