import { searchJob } from "services/Collections";
import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Loader2 } from "lucide-react";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateHeader } from "store/slices/StmateSlice";

const Header = ({ jobDetails, header, setHeader }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.LoginSlice?.data);
  const currentJob = useSelector((state) => state?.STMate?.outsideJob);
  const headerData = useSelector((state) => state?.STMate?.header);


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchJobDetails = async () => {
    if (!currentJob || currentJob == "123") {
      setError("You have to select the job");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      let params = new URLSearchParams();
      params.append("job", currentJob);
      params.append("user_id", currentUser?.id);
      let res = await searchJob(params?.toString());
      if (res && res.data) {
        let obj = {
          job_number: res.data.job.number || "",
          job_type: res.data.job.type || "",
          status: res.data.job.status || "",
          service_titan: res.data.servicetitan_link || "",
          business_unit: res.data.business_unit || "",
          customer: res.data.customer || "",
          campaign: res.data.campaign || "",
          technicians: res.data.technicians?.toString() || "",
        };
        dispatch(updateHeader({ data: obj, currentJob }));
      } else {
        dispatch(updateHeader(null));
        throw new Error("No data received from the server");
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!headerData && currentJob !== headerData?.currentJob) {
      fetchJobDetails();
    }
  }, []);

  return (
    <>
      <div
        onClick={() => setHeader(true)}
        className={`w-full bg-[#474747] flex justify-center items-center gap-2 cursor-pointer text-white ${
          header ? "h-0 hidden" : "h-[40px] block"
        }`}
      >
        <MdKeyboardDoubleArrowDown
          style={{ width: "24px", height: "24px" }}
          color="currentColor"
        />
        <span>
          {jobDetails?.job_type} {jobDetails?.customer && "||"}{" "}
          {jobDetails?.customer}
        </span>
      </div>

      <div
        className={`w-full bg-[#515151] text-white font-bold px-[16px] ${
          !header ? "hidden" : "block"
        }`}
      >
        <div className="px-[81px] py-[21px]">
          {loading ? (
            <div className="flex justify-center items-center">
              <Loader2 className="h-8 w-8 animate-spin text-white" />
            </div>
          ) : error ? (
            <p style={{ textAlign: "center" }}>{error}</p>
          ) : jobDetails ? (
            <div className="grid grid-cols-2 gap-40 text-sm max-935:gap-20 max-935:text-xs max-885:gap-10 max-885:text-xxs">
              <div className="space-y-2">
                {Object?.entries(jobDetails)
                  ?.slice(0, 4)
                  ?.map(([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center bg-[#D9D9D9] text-black px-2 py-3"
                    >
                      <span className="capitalize font-medium">
                        {key.replace("_", " ")}:
                      </span>
                      {key == "service_titan" ? (
                        <a
                          className="text-blue-500"
                          href={value}
                          target="_blank"
                        >
                          Link
                        </a>
                      ) : (
                        <span>{value}</span>
                      )}
                    </div>
                  ))}
              </div>
              <div className="space-y-2">
                {Object.entries(jobDetails)
                  ?.slice(4)
                  ?.map(([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center bg-[#D9D9D9] text-black px-2 py-3"
                    >
                      <span className="capitalize font-medium">
                        {key.replace("_", " ")}:
                      </span>
                      <span>{value}</span>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div
        onClick={() => setHeader(false)}
        className={`w-full h-[40px] bg-[#474747] flex justify-center items-center gap-2 cursor-pointer text-white ${
          !header ? "h-0 hidden" : "h-[40px] block"
        }`}
      >
        <MdKeyboardDoubleArrowUp
          style={{ width: "24px", height: "24px" }}
          color="currentColor"
        />
        <span>Close</span>
      </div>
    </>
  );
};

export default Header;
