import React from "react";
import styled from "styled-components";

export const Group = () => {
  return <Container>Group Chat</Container>;
};

const Container = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Lato", sans-serif;
`;
