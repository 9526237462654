import { MenuIcon, DoubleArrowRightIcon } from "utils/Icons/SvgIcons";
import Sidenav from "components/Sidenav";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

const PrivateLayout = () => {
  const navigate = useNavigate();
  const [arrowbutton, setArrowbutton] = useState(true);

  return (
    <PrivateLayoutWrapper>
      <div className="nav-bar">
        {arrowbutton ? (
          <i onClick={() => setArrowbutton(false)}>
            <MenuIcon />
          </i>
        ) : (
          <i onClick={() => setArrowbutton(true)}>
            <DoubleArrowRightIcon />
          </i>
        )}
        <span>TRIO</span>
      </div>
      <section
        className={arrowbutton ? "small-sidebar-section" : "sidebar-section"}
      >
        <Sidenav arrowbutton={arrowbutton} setArrowbutton={setArrowbutton}/>
      </section>

      <section className="outlet-section">
        <div className="outlet-section-inner">
          <Outlet />
          {/* <div className="nothing">
            <NothingSelected />
          </div> */}
        </div>
      </section>
    </PrivateLayoutWrapper>
  );
};

export default PrivateLayout;

const PrivateLayoutWrapper = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  background: #41628a;
  /* transition: all 0.3s; */

  .nav-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    z-index: 999;
    height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(65, 98, 138);
    justify-content: left;
    padding: 0 20px;
    color: white;
    font-size: 26px;
    font-weight: 600;

    i {
      cursor: pointer;
    }
  }

  .sidebar-section {
    width: 320px;
    height: 100vh;
    background: #41628a;
    position: absolute;
    transform: translateX(-0%);
    transition: 0.3s;
    z-index: 9;

    @media (max-height: 800px) {
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }
    /* overflow-y: scroll; */
    /* @media (max-height: 800px) {
      overflow-y: auto;
    } */

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }

    // @media (max-width: 1440px) {
    //   width: 200px;
    // }
    /* @media (max-width: 992px) {
      display: ${({ sidebar }) => !sidebar && "none"};
    } */
  }
  .outlet-section {
    width: 100%;
    /* margin-left: 320px; */
    margin-top: 50px;
    background: #41628a;
    height: calc(100vh - 50px);
    // @media (max-height: 850px) {
    //   height: 100vh;
    // }

    .outlet-section-inner {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fafafa;
      /* border-top-left-radius: 20px; */
      overflow-y: auto;
      @media (max-width: 992px) {
        border-top-left-radius: 0;
      }
    }

    // @media (max-width: 1440px) {
    //   margin-left: 200px;
    //   width: calc(100% - 200px);
    // }

    /* @media (max-width: 992px) {
      width: 100%;
      margin: 0;
      height: 100%;
    } */
  }

  .small-sidebar-section {
    position: absolute;
    transform: translateX(-100%);
    transition: 0.3s;

    width: 320px;
    height: 100vh;
    background: #41628a;
    /* position: relative; */
    z-index: 9;
    @media (max-height: 800px) {
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }
    /* overflow-y: scroll; */
    /* @media (max-height: 800px) {
      overflow-y: auto;
    } */

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }
  }
  .nothing {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
